ul {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
font {
  font-size: 100%;
  font-weight: normal;
}
ul,
li {
  list-style-type: none;
}
button {
  background: none;
}
button:hover {
  cursor: pointer;
}
.common-title {
  color: #000000;
  font-size: 0.23958rem;
  text-align: center;
  font-weight: bold;
  line-height: 0.26042rem;
  display: none;
}
.common-logo > img {
  width: 100%;
  height: 100%;
}
.common-title-sub {
  text-align: center;
  margin-top: 0.09896rem;
  color: #404040;
  font-size: 0.15625rem;
  font-weight: bold;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 0.09375rem;
  color: #A6A6A6;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  font-size: 0.09375rem;
  color: #A6A6A6;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  font-size: 0.09375rem;
  color: #A6A6A6;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-size: 0.09375rem;
  color: #A6A6A6;
}
.common-flex {
  display: flex;
  justify-content: center;
}
.common-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.common-btn {
  cursor: pointer;
}
.common-btn > img {
  margin-left: 0.08854rem;
}
.common-btn:hover {
  color: #ffffff;
  box-shadow: none;
  font-weight: bold;
  transform: translate3d(0, -0.00521rem, 0);
  transition: transform 0.2s linear;
  -webkit-transition: transform 0.2s linear;
  background: linear-gradient(-90deg, #0bfc6e, #63def5);
}
.common-btn-bg {
  background: linear-gradient(90deg, #1162f0, #761f99);
  color: #FFFFFF;
  font-size: 0.09375rem;
}
.common-btn-bg:hover {
  transform: translate3d(0, -0.01042rem, 0);
  transition: transform 0.3s linear;
  -webkit-transition: transform 0.3s linear;
  font-weight: bold;
}
.common-btn-bd {
  font-size: 0.09375rem;
  padding: 0.01042rem;
  color: #454545;
  text-align: center;
  background: linear-gradient(-90deg, #c110ff, #8041ff, #8041ff, #6ae6ff);
}
.common-btn-bd > button {
  background: #ffffff;
}
.common-btn-bd > button:hover {
  background: none;
  transition: transform 0.2s linear;
  -webkit-transition: transform 0.2s linear;
}
.common-btn-bd:hover {
  background: linear-gradient(90deg, #78ffff, #805aff, #c41fff);
  box-shadow: 0 0.01042rem 0.05208rem 0 rgba(255, 255, 255, 0.34);
  color: #ffffff;
  transition: transform 0.2s linear;
  transform: translate3d(0, -0.00521rem, 0);
  -webkit-transition: transform 0.2s linear;
}
input,
textarea,
button {
  color: inherit;
  font: inherit;
  margin: 0;
  outline: none;
  border: none;
  line-height: normal;
}
button {
  text-transform: none;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.maodianTop {
  position: absolute;
  top: -0.49479rem;
  width: 0.52083rem;
  height: 0.52083rem;
}
@font-face {
  font-family: "Century Gothic";
  src: url(/static/font/CenturyGothic.TTF);
}
@font-face {
  font-family: "Noto Sans SC";
  src: url('https://fonts.googleapis.com/css?family=Noto+Sans+SC:100,300,400,500,700,900"');
  font-display: swap;
}
.video-react .video-react-big-play-button {
  width: 0.41667rem;
  height: 0.41667rem;
  border-radius: 50%;
  background: none;
  border: 0.01042rem solid #00aaee;
}
.video-react .video-react-big-play-button::before {
  top: 0.05208rem;
  font-size: 0.23438rem;
  color: #00aaee;
}
.video-react:hover .video-react-big-play-button {
  background: none;
  border: 0.01042rem solid #00aaee;
}

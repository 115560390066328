.sem-anget-container img {
  display: block;
  width: 100vw;
}
.sem-apply {
  position: relative;
}
.sem-apply-box {
  position: absolute;
  top: 2.08333rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.sem-apply-box .toast-box {
  position: fixed;
  font-size: 0.44792rem;
  color: #FFFFFF;
  width: 6.73438rem;
  height: 0.98958rem;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 0.07813rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1;
}
.sem-apply-box-content {
  background: transparent;
  font-size: 0.39063rem;
}
.sem-apply-box-body {
  width: 85%;
  margin: 0 auto;
}
.sem-apply-box-body .sem-apply-box-input-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sem-apply-box-body .sem-apply-box-input-item {
  margin-bottom: 0.46875rem;
}
.sem-apply-box-body .sem-apply-box-input-item-label {
  font-weight: bold;
}
.sem-apply-box-body span {
  color: red;
}
.sem-apply-box-body input {
  width: 100%;
  margin-top: 0.10417rem;
  height: 1.04167rem;
  border-radius: 0.26042rem;
  text-indent: 0.15625rem;
  color: #333333;
  border: 0.00521rem solid #cccccc;
}
.sem-apply-box-body input::placeholder {
  font-size: 0.39063rem;
}
.sem-apply-box-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E82D34;
  margin: 0 auto;
  margin-top: 0.26042rem;
  color: #ffffff;
  font-size: 0.39063rem;
  width: 8.85417rem;
  height: 1.14583rem;
  border-radius: 1.04167rem;
}

.mouse {
  position: absolute;
  bottom: 0.25521rem;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.mouse .mouse-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mouse-icon {
  display: block;
  width: 0.10938rem;
  height: 0.15104rem;
  background-image: url(https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/mouse-icon.png);
  background-size: 100% 100%;
}
.mouse-text {
  color: #ffffff;
  font-size: 0.07292rem;
  margin-left: 0.05208rem;
}

.product .common-title,
.product .common-title-sub {
  color: #FFFFFF;
}
.product .product-common-btn-bg {
  background: linear-gradient(90deg, #1162f0, #761f99);
  color: #FFFFFF;
}
.product .product-banner-btn-bd {
  padding: 0.01042rem;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(90deg, #c110ff, #8041ff, #8041ff, #6ae6ff);
}
.product .product-banner-btn-bd > button {
  background: #1D1D20;
}
.product .product-banner-btn-bd > button:hover {
  background: none;
  transition: 0.2s all linear;
  -webkit-transition: 0.2s all linear;
  font-weight: bold;
}
.product .product-banner-btn-bd:hover {
  background: linear-gradient(90deg, #78ffff, #805aff, #c41fff);
  transition: 0.2s all linear;
  transform: translate3d(0, -0.00521rem, 0);
  -webkit-transition: 0.2s all linear;
}
.product .product-banner-one {
  width: 100%;
  background: #1A191E;
}
.product .product-banner-one-container {
  width: 5.77083rem;
  margin: 0 auto;
  padding-top: 1.04167rem;
}
.product .product-banner-one-title {
  display: flex;
  margin-bottom: 0.11979rem;
}
.product .product-banner-one-title-left {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.product .product-banner-one-title-left > img {
  width: 2.28646rem;
  display: block;
}
.product .product-banner-one-title-left > h3 {
  font-weight: bold;
  font-size: 0.3125rem;
  color: #FFFFFF;
  margin-top: 0.11979rem;
}
.product .product-banner-one-title-img {
  width: 0.47396rem;
  height: 1.00521rem;
}
.product .product-banner-one-title-img.img2 {
  margin-left: 0.08333rem;
}
.product .product-banner-one .device_item {
  color: #ffffff;
}
.product .product-banner-one .device_item.s5 .device_item_body {
  height: 3.92188rem;
}
.product .product-banner-one .device_item.s10 .device_item_body {
  height: 3.77083rem;
}
.product .product-banner-one .device_item.l20 .device_item_body {
  height: 7.56771rem;
}
.product .product-banner-one .device_item .device_item_body {
  position: relative;
  width: 5.77083rem;
  margin: auto;
}
.product .product-banner-one .device_item .device_item_body .svg_mask {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 2929PX;
  background: #1A191E;
  z-index: 2;
}
.product .product-banner-one .device_item .device_item_body .svg_mask.device_s5_animate {
  animation: show_s5 3s forwards;
  -webkit-animation: show_s5 3s forwards;
}
.product .product-banner-one .device_item .device_item_body .svg_mask.device_s10_animate {
  animation: show_s10 3s forwards;
  -webkit-animation: show_s10 3s forwards;
}
.product .product-banner-one .device_item .device_item_body .svg_mask.device_l20_ll40_animate {
  animation: show_l20_ll40 6s forwards;
  -webkit-animation: show_l20_ll40 6s forwards;
}
@keyframes show_s5 {
  0% {
    top: 0;
  }
  100% {
    top: 753PX;
  }
}
@keyframes show_s10 {
  0% {
    top: 753PX;
  }
  100% {
    top: 1477PX;
  }
}
@keyframes show_l20_ll40 {
  0% {
    top: 1477PX;
  }
  100% {
    top: 2929PX;
  }
}
.product .product-banner-one .device_item .device_item_body .svg_s5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.product .product-banner-one .device_item .device_item_body .svg_s5 .svg_s5_path_1.animate {
  animation: slideDown1 7.6s forwards;
  -webkit-animation: slideDown1 7.6s forwards;
}
.product .product-banner-one .device_item .device_item_body .svg_s5 .svg_s5_path_2.animate {
  animation: slideDown2 7.6s forwards;
  -webkit-animation: slideDown2 7.6s forwards;
}
.product .product-banner-one .device_item .device_item_main {
  position: relative;
  display: flex;
  z-index: 2;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left > img {
  display: block;
  width: 2.06771rem;
  height: 2.03125rem;
  margin-top: 0.91146rem;
  margin-left: 0.26042rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left > p {
  margin-top: 0.20833rem;
  margin-left: 0.46875rem;
  font-size: 0.21875rem;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0 0.02083rem 0.05208rem rgba(0, 0, 0, 0.36);
  white-space: nowrap;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left > p > i {
  font-size: 0.22917rem;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
  text-shadow: 0 0.02083rem 0.05208rem rgba(0, 0, 0, 0.36);
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left.s10 > p {
  margin-top: 0.69271rem;
  margin-left: 1.19792rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left.s10 > img {
  margin-top: 0.11458rem;
  margin-left: 1.22396rem;
  width: 2.51042rem;
  height: 2.21875rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left.l20 > p {
  margin-top: 0.54167rem;
  margin-left: 0.71354rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left.l20 > img {
  margin-top: 0.11458rem;
  margin-left: 0.82813rem;
  width: 2.10417rem;
  height: 2.47917rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left.ll40 > p {
  margin-top: 1.04167rem;
  margin-left: -0.26042rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_left.ll40 > img {
  margin-top: 0.17188rem;
  margin-left: -0.18229rem;
  width: 2.45313rem;
  height: 2.58333rem;
}
.product .product-banner-one .device_item .device_item_main .slide-left {
  transition: transform 0.8s linear;
  transform: translate3d(0.15625rem, 0, 0);
  -webkit-transition: transform 0.8s linear;
}
.product .product-banner-one .device_item .device_item_main .slide-right {
  transition: 0.8s all linear;
  transform: translate3d(-0.15625rem, 0, 0);
  -webkit-transition: 0.8s all linear;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right {
  padding-left: 1.02604rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right .device_text {
  display: block;
  width: 1.08333rem;
  height: 0.72917rem;
  margin-top: 1.30729rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right .device_logo {
  display: block;
  height: 0.21875rem;
  margin-top: 0.17708rem;
  margin-bottom: 0.0625rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right .device_text_line {
  display: flex;
  font-size: 0.10417rem;
  align-items: center;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right .device_text_line > .tag {
  display: flex;
  width: 0.26042rem;
  height: 0.15104rem;
  background: linear-gradient(-90deg, #1162f0, #73308e);
  border-radius: 0.07813rem;
  font-size: 0.0625rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right .device_text_line > .tag:hover {
  background: linear-gradient(90deg, #78ffff, #805aff, #c41fff);
  font-weight: bold;
  transition: 0.2s all linear;
  transform: translate3d(0, -0.00521rem, 0);
  -webkit-transition: 0.2s all linear;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right.s10 {
  padding-left: 0.13021rem;
  width: 1.67188rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right.s10 .device_text {
  width: 1.67188rem;
  height: 0.72917rem;
  margin-top: 1.71875rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right.l20 {
  padding-left: 0.18229rem;
  width: 1.58854rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right.l20 .device_text {
  width: 1.58854rem;
  height: 0.72917rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right.ll40 {
  padding-left: 1.22396rem;
}
.product .product-banner-one .device_item .device_item_main .device_item_main_right.ll40 .device_text {
  margin-top: 2.08333rem;
  width: 2.14583rem;
  height: 0.72917rem;
}
@keyframes slideDown {
  0% {
    d: path("M302.000,0.000 L302.000,-0.001 L344.000,-0.001 L344.000,0.000 L302.000,0.000 ZM-0.000,-0.001 L284.000,-0.001 L284.000,0.000 L-0.000,0.000 L-0.000,-0.001 Z");
  }
  100% {
    d: path("M302.000,750.000 L302.000,-0.001 L344.000,-0.001 L344.000,750.000 L302.000,750.000 ZM-0.000,-0.001 L284.000,-0.001 L284.000,750.000 L-0.000,750.000 L-0.000,-0.001 Z");
  }
}
.product .product-banner-btn-bd-wrap {
  display: flex;
  justify-content: center;
  margin-top: 0.27604rem;
}
.product .product-banner-btn-bd-wrap.tag-two {
  margin-left: 0.21354rem;
}
.product .product-banner-btn-bd-wrap.tag-three {
  margin-left: 0.21875rem;
}
.product .product-banner-btn-bd-wrap.tag-four {
  margin-left: 0.24479rem;
}
.product .product-banner-two {
  width: 100%;
  background: #1D1D20;
  padding: 0.52083rem 0 0.55208rem 0;
  position: relative;
  z-index: 3;
}
.product .product-banner-two .product_banner_img {
  margin: 0 auto;
  color: #FFFFFF;
  font-size: 0.26042rem;
  text-align: center;
}
.product .product-banner-two .product_banner_img > .img_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.02604rem;
}
.product .product-banner-two .product_banner_img > .img_box > img {
  width: 1.60417rem;
  height: 0.26042rem;
  margin-top: 0.07813rem;
  margin-right: 0.02604rem;
}
.product .product-banner-two-btn {
  display: flex;
  padding: 0.01042rem;
  border-radius: 0.26042rem;
  align-items: center;
}
.product .product-banner-two-btn > button {
  border-radius: 0.0625rem;
  font-size: 0.0625rem;
  padding: 0.03125rem 0.08854rem;
  border-radius: 0.26042rem;
}
.product .product-banner-two-list {
  padding: 0 1.77604rem;
  justify-content: space-between;
}
.product .product-banner-two-list-item {
  padding-top: 0.36458rem;
}
.product .product-banner-two-list-item .device_text_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.product .product-banner-two-list-item .tag {
  padding: 0.03646rem 0.08333rem 0.03125rem 0.08333rem;
  display: inline-block;
  border-radius: 0.0625rem;
  font-size: 0.0625rem;
  margin: 0.20313rem 0 0.16667rem 0;
}
.product .product-banner-two-list-item .title {
  font-size: 0.10417rem;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product .product-banner-two-list-item .title > img {
  position: relative;
  width: 0.625rem;
  height: 0.09896rem;
  top: 0.01042rem;
}
.product .product-banner-two-list-item .title > p {
  margin-left: 0.03125rem;
}
.product .product-banner-two-list-item > .list {
  margin-top: 0.20833rem;
  height: 0.83333rem;
  padding: 0;
}
.product .product-banner-two-list-item > .list > li {
  color: #FFFFFF;
  font-size: 12PX;
  line-height: 0.13542rem;
  list-style: none;
  text-align: center;
}
.product .product-banner-two-list-item .device-img-box {
  height: 1.74479rem;
  background-repeat: no-repeat;
  background-position: bottom left;
}
.product .product-banner-two-list-item .device-img-box.one {
  width: 1.09896rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fproduct-banner-1_1.png");
  background-size: 0.93229rem 0.96354rem;
  background-position: bottom center;
}
.product .product-banner-two-list-item .device-img-box.two {
  width: 1.20313rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fproduct-banner-2.png");
  background-size: 100% 1.01563rem;
}
.product .product-banner-two-list-item .device-img-box.three {
  width: 0.5625rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fproduct-banner-3.png");
  background-size: 0.53125rem 1.70833rem;
}
.product .product-banner-two-list-item .device-img-box.four {
  width: 0.64583rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fproduct-banner-4.png");
  background-size: 0.66667rem 1.74479rem;
}
.product .product-banner-three {
  position: relative;
  z-index: 3;
  background: linear-gradient(90deg, #0f1734, #300b28);
  padding: 0.64063rem 0 0.26042rem 0;
}
.product .product-banner-three-logo > span {
  color: #FFFFFF;
  font-size: 0.23958rem;
}
.product .product-banner-three-logo > .logo5 {
  width: 1.6875rem;
  height: 0.29167rem;
  margin-top: 0.02604rem;
  margin-right: 0.05208rem;
}
.product .product-banner-three .common-text-middle {
  font-size: 0.20833rem;
  margin-bottom: 0.10417rem;
  opacity: 0.4;
  color: #FFFFFF;
}
.product .product-banner-three .common-title-sub {
  font-size: 0.1875rem;
  margin: 0.07813rem 0 0 0.20833rem;
}
.product .product-banner-three-btn {
  width: 0.67708rem;
  height: 0.26042rem;
  border-radius: 0.13021rem;
  font-size: 0.09375rem;
  transition: all 0.7s linear 0.7s;
}
.product .product-banner-three-btn > button {
  width: 0.67708rem;
  height: 0.26042rem;
  border-radius: 0.13021rem;
  background: #1C1232;
}
.product .product-banner-three-btn.btn-animation {
  opacity: 1;
}
.product .product-banner-three .common-text-big {
  font-size: 0.28125rem;
  font-weight: 800;
  color: #FFFFFF;
  opacity: 0.4;
  transition: opacity 0.3s;
}
.product .product-banner-three .fly-left {
  opacity: 1;
  animation: titleFlyLeft 0.7s linear;
  -moz-animation: titleFlyLeft 0.7s linear;
  -webkit-animation: titleFlyLeft 0.7s linear;
  -o-animation: titleFlyLeft 0.7s linear;
}
@keyframes titleFlyLeft {
  from {
    opacity: 0;
    font-size: 0.0625rem;
  }
  to {
    opacity: 1;
    font-size: 0.28125rem;
  }
}
.product .product-banner-three .common-text-small {
  transition: all 1s linear;
  font-size: 0.125rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.21875rem;
  margin: 0.26042rem 0 0.32813rem 0;
  opacity: 0.4;
}
.product .product-banner-three .line .common-text-big {
  opacity: 1;
}
.product .product-banner-three .fly-right {
  opacity: 1;
  animation: titleFlyRight 0.7s linear;
  -moz-animation: titleFlyRight 0.7s linear;
  -webkit-animation: titleFlyRight 0.7s linear;
  -o-animation: titleFlyRight 0.7s linear;
}
@keyframes titleFlyRight {
  from {
    opacity: 0;
    font-size: 0.0625rem;
  }
  to {
    opacity: 1;
    font-size: 0.125rem;
  }
}
.product .product-banner-three-list {
  padding: 0.26042rem 0.15625rem;
}
.product .product-banner-three-list-item {
  width: 3.02083rem;
  height: 3.97396rem;
  background: #000000;
  position: relative;
  overflow: hidden;
}
.product .product-banner-three-list-item:nth-child(2) {
  margin: 0 0.26042rem;
}
.product .product-banner-three-list-item > img {
  position: absolute;
  opacity: 0;
}
.product .product-banner-three-list-item.left > img {
  width: 2.39583rem;
  height: 2.77604rem;
  top: 22%;
  left: 0.58333rem;
}
.product .product-banner-three-list-item.left > img.left-animation {
  animation: flyLeft 3s;
  -moz-animation: flyLeft 3s;
  -webkit-animation: flyLeft 3s;
  -o-animation: flyLeft 3s;
  opacity: 1;
}
@keyframes flyLeft {
  from {
    opacity: 0;
    left: 3.02083rem;
  }
  to {
    opacity: 1;
    left: 0.58333rem;
  }
}
.product .product-banner-three-list-item.center > img {
  width: 2.39583rem;
  height: 3.04688rem;
  bottom: 0;
  left: 0.32292rem;
}
.product .product-banner-three-list-item.right > img {
  width: 1.41667rem;
  height: 2.99479rem;
  left: 0.8125rem;
  top: 0.46875rem;
}
.product .product-banner-three-list-item.right > img.right-animation {
  animation: flyRight 3s;
  -moz-animation: flyRight 3s;
  -webkit-animation: flyRight 3s;
  -o-animation: flyRight 3s;
  opacity: 1;
}
@keyframes flyRight {
  from {
    opacity: 0;
    left: -1.5625rem;
  }
  to {
    opacity: 1;
    left: 0.8125rem;
  }
}
.product .product-banner-three .center-animation {
  animation: flyCenter 3s;
  -moz-animation: flyCenter 3s;
  -webkit-animation: flyCenter 3s;
  -o-animation: flyCenter 3s;
  opacity: 1 !important;
}
@keyframes flyCenter {
  from {
    opacity: 0;
    bottom: -3.04688rem;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}
.product .product-banner-three-content {
  width: 9.54167rem;
  margin: 0 auto;
  height: 4.73438rem;
  color: #FFFFFF;
  align-items: center;
  background: linear-gradient(55deg, #161616, #151532, #2f0b28);
}
.product .product-banner-three-content .right {
  width: 3.28125rem;
}
.product .product-banner-three-content .left > img {
  width: 3.44792rem;
  height: 3.58854rem;
  margin-right: 1.1875rem;
}
.product .product-banner-three-content .product-banner10 {
  width: 3.73958rem !important;
  height: 1.6875rem !important;
  margin-right: 0.77083rem !important;
}
.product .product-banner-three-content .product-banner8 > img {
  width: 3.23438rem;
  height: 3.70313rem;
}
.product .product-banner-three-content .product-banner14 > img {
  width: 4.17188rem;
  height: 2.55208rem;
}
.product .product-banner-three-content-list {
  margin-top: 0.26042rem;
  font-size: 0.125rem;
  opacity: 0.5;
}
.product .product-banner-three-content-list > li {
  margin-bottom: 0.05208rem;
}
.product .product-banner-three-content .pinzhi-box {
  width: 2.6875rem;
  height: 3.13021rem;
  margin-right: 2.1875rem;
  position: relative;
}
.product .product-banner-three-content .pinzhi-box.left > img {
  width: 1.47396rem;
  height: 3.13021rem;
  position: absolute;
  left: 1.5rem;
}
.product .product-banner-three-content .pinzhi-box.left > .pinzhi {
  z-index: 6;
}
.product .product-banner-three-content .pinzhi-box.left > .pinzhi1 {
  width: 2.26563rem;
  height: 3.33854rem;
  left: 1.04167rem;
  top: -0.20313rem;
  z-index: 5;
}
.product .product-banner-three-content .pinzhi-box.left > .pinzhi2 {
  width: 2.86458rem;
  height: 3.23438rem;
  left: 0.71354rem;
  top: -0.17188rem;
  z-index: 4;
}
.product .product-banner-three-content .pinzhi-box.left > .pinzhi3 {
  width: 3.23438rem;
  height: 2.85938rem;
  left: 0.52083rem;
  top: -0.05208rem;
  z-index: 3;
}
.product .product-banner-three-content .pinzhi-box.left > .pinzhi4 {
  width: 3.33854rem;
  height: 2.27083rem;
  z-index: 2;
  left: 0.44271rem;
  top: 0.20833rem;
}
.product .product-banner-three-content .pinzhi-box.left > .pinzhi5 {
  width: 3.16667rem;
  height: 1.49479rem;
  left: 0.52083rem;
  top: 0.58333rem;
}
@keyframes changeBig {
  from {
    opacity: 0;
    width: 0.00521rem;
    height: 0.00521rem;
    top: 0.78125rem;
    left: 0.78125rem;
  }
  to {
    opacity: 1;
    width: 2.6875rem;
    height: 2.57813rem;
    top: 0;
    left: 0;
  }
}
.product .product-banner-three-content.last {
  width: 100%;
}
.product .product-banner-three-content-off {
  padding: 0.15625rem 0;
  margin: 0 0.22917rem;
}
.product .product-banner-three-content-off .left {
  width: 5.02604rem;
  height: 3.83854rem;
  margin-left: 0.18229rem;
}
.product .product-banner-three-content-off .left > img {
  width: 100%;
  height: 100%;
}
.product .product-banner-three-content-off .left.product-banner12-left {
  background: linear-gradient(-90deg, #8719dc, #5132d8);
  position: relative;
  overflow: hidden;
}
.product .product-banner-three-content-off .left.product-banner12-left > img {
  width: 3.96875rem;
  height: 2.51563rem;
  position: absolute;
  opacity: 0;
  bottom: 0;
}
.product .product-banner-three-content-off .left.product-banner9-left {
  background: linear-gradient(-90deg, #300B28, #0C132A);
  text-align: center;
}
.product .product-banner-three-content-off .left.product-banner13 {
  background: linear-gradient(-30deg, #27078A, #360F39, #200939, #0B0244);
  align-items: center;
  margin-top: 0.01563rem;
}
.product .product-banner-three-content-off .left.product-banner13 > img {
  width: 3.67708rem;
  height: 3.125rem;
}
.product .product-banner-three-content-off .left .product-banner9 {
  width: 3.82813rem;
  height: 3.83854rem;
}
.product .product-banner-three-content-off .right {
  width: 4.33333rem;
  background: linear-gradient(-90deg, #0C132A, #300B28);
  padding: 0.98958rem 0 0 0.9375rem;
  box-sizing: border-box;
}
.product .product-banner-three-content-off .right.product-banner12-right {
  background: linear-gradient(90deg, #8719dc, #5132d8);
}
.product .product-banner-three-content-off .right.product-banner12-right > .product-banner-three-btn {
  background: linear-gradient(90deg, #c110ff, #8041ff, #8041ff, #6ae6ff);
}
.product .product-banner-three-content-off .right.product-banner12-right > .product-banner-three-btn > button {
  background: #7A1FDB;
}
.product .product-banner-three-content-off .right.product-banner12-right > .product-banner-three-btn > button:hover {
  background: none;
}
.product .product-banner-three-content-off .right.product-banner13-right {
  background: linear-gradient(-30deg, #090056, #1A0739, #310E39, #320D58);
}
.product .product-banner-three-content-off .right.product-banner13-right > .product-banner-three-btn > button {
  background: #230A39;
}
.product .product-banner-three-content-off .right.product-banner13-right > .product-banner-three-btn > button:hover {
  background: none;
}
.product .content-wrap {
  width: 6.14583rem;
  margin: 0 auto;
  padding-top: 0.52083rem;
}
.product .content-wrap .content-box {
  margin-left: auto;
  width: 2.55208rem;
  display: flex;
  flex-direction: column;
}
.product .content-wrap .content-box.right {
  margin-left: 0;
  width: 100%;
  margin-right: auto;
  align-items: center;
  text-align: center;
}
.product .content-wrap .content-box .content-title {
  font-size: 0.25rem;
  color: #333333;
  font-weight: bold;
  line-height: 0.34896rem;
  justify-content: center;
}
.product .content-wrap .content-box .content-title.white {
  color: #FFFFFF;
}
.product .content-wrap .content-box .content-img {
  height: 2.71354rem;
  width: 2.70833rem;
  margin-top: 0.33854rem;
  background-image: url("https://chargespot.oss-accelerate.aliyuncs.com/chargespot/img_battery_design.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.product .content-wrap .content-box .content-descript {
  margin: 0.15625rem 0;
  width: 4.07813rem;
  font-size: 0.08333rem;
  color: #666666;
  font-weight: 500;
  line-height: 0.14583rem;
}
.product .content-wrap .content-box .content-descript.white {
  color: #FFFFFF;
}
.product .content-wrap .content-box .content-split {
  width: 0.15625rem;
  height: 0.02083rem;
  background: #0591b4;
}
.product .content-wrap .content-box .content-split.white {
  background: #FFFFFF;
}
.product .banner-title {
  font-size: 0.25rem;
  color: #333333;
  font-weight: bold;
  text-align: center;
  line-height: 0.34896rem;
  padding-top: 0.52083rem;
}
.product .banner-title.white {
  color: #ffffff;
}
.product .banner-descript {
  width: 3.52604rem;
  font-size: 0.08333rem;
  color: #666666;
  text-align: center;
  line-height: 0.14583rem;
  margin: 0.15625rem auto;
}
.product .banner-descript.white {
  color: #ffffff;
}
.product .banner-descript.white.bottom {
  margin: 0.88542rem auto 0.52083rem auto;
}
.product .banner-descript.max {
  width: 4.27604rem;
}
.product .banner-split {
  background: #0591B4;
  width: 0.15625rem;
  height: 0.02083rem;
  margin: 0 auto;
}
.product .banner-split.white {
  background: #ffffff;
}
.product .content-recycler-imgs {
  display: flex;
  margin-top: 0.79688rem;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.product .content-recycler-imgs span {
  width: 0.15625rem;
  height: 0.15625rem;
  background-image: url("https://chargespot.oss-accelerate.aliyuncs.com/chargespot/icon_cha.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 0.59896rem;
}
@keyframes slideDown2 {
  0% {
    d: path("M390,0c14,0,28,0,42,0c0,249.64,0,499.36,0,749c224.64,240.64,449.36,481.36,674,722c-34.65, 61.37-62.15, 128.17-91, 194c-49.76, 113.55-103.4, 226.64-154, 342c-132.68, 302.48-272, 605.74-399, 912c-17.33, 0-34.67, 0-52, 0 c148.32-339.97, 296.68-680.03, 445-1020c64.99-142.65, 130.01-285.35, 195-428c-219.98-240.64-440.02-481.36-660-722 C390, 499.36, 390, 249.64, 390, 0z");
  }
  100% {
    d: path("M390,0c14,0,28,0,42,0c0,249.64,0,499.36,0,749c224.64,240.64,449.36,481.36,674,722c-34.65, 61.37-62.15, 128.17-91, 194c-49.76, 113.55-103.4, 226.64-154, 342c-132.68, 302.48-272, 605.74-399, 912c-17.33, 0-34.67, 0-52, 0 c148.32-339.97, 296.68-680.03, 445-1020c64.99-142.65, 130.01-285.35, 195-428c-219.98-240.64-440.02-481.36-660-722 C390, 499.36, 390, 249.64, 390, 0z");
  }
}

a {
  color: #666666;
  text-decoration: none;
}
html {
  font-size: 16PX;
  font-family: "Noto Sans SC", Helvetica, "PingFangSC", "Microsoft Yahei", Arial, sans-serif;
}
#cnzz_stat_icon_1276191016 {
  display: none;
}

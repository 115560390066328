.wrapper {
  height: 100vh;
  width: 100%;
  min-width: 5.20833rem;
  background: #FFF;
  display: table;
}
.wrapper .container {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.wrapper .container .img {
  margin: 0 auto;
}
.wrapper .container .img.bgImg {
  width: 3.64583rem;
  height: 2.34375rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.wrapper .container .img.bgImg.img404 {
  background-image: url(/static/imgs/404.png);
}
.wrapper .container .img.bgImg.img500 {
  background-image: url(/static/imgs/500.png);
}
.wrapper .container .tips {
  height: 0.11458rem;
  line-height: 0.11458rem;
  font-size: 0.08333rem;
  font-weight: 400;
  color: #666;
  text-align: center;
}
.wrapper .container .tips .back {
  color: #1890ff;
  transition: color 400ms ease;
}
.wrapper .container .tips .back:hover {
  color: #5cabf5;
}

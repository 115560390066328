.using {
  background-color: #E9F0F2;
}
.using .video-react-play-progress:before {
  top: -0.46em;
}
.using .video-react-progress-control:hover .video-react-play-progress:before {
  top: -0.33333333em;
}
.using-head {
  height: 0.52083rem;
}
.using-title {
  background-color: #fff;
}
.using-title-body {
  width: 6.25rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.using-title-right {
  margin-left: auto;
}
.using-title-right button {
  padding: 0.02604rem 0.16146rem;
  line-height: 0.14583rem;
  color: #333333;
  font-size: 0.09375rem;
  border-radius: 0.09375rem;
  background: #F6F6F6;
  transition: transform 0.2s linear;
}
.using-title-right button:hover {
  transform: translateY(-0.01042rem);
}
.using-title-right button:nth-child(2) {
  margin: 0 0.10417rem;
}
.using-title-right button.active {
  background: #242526;
  color: #fff;
  font-weight: bold;
}
.using-title-left {
  height: 0.625rem;
  line-height: 0.625rem;
  display: flex;
}
.using-title-left > .big {
  font-size: 0.23958rem;
  color: #333333;
  font-weight: bold;
}
.using-title-left > .small {
  font-size: 0.13542rem;
  color: #333333;
  margin-top: 1%;
  margin-left: 0.05208rem;
}
.using .using-waterfall {
  padding: 0 1.92708rem;
}
.using .using-content {
  position: relative;
  padding-top: 0.39063rem;
  background: #f9f9f9;
}
.using .using-content .using-body {
  position: relative;
  width: 6.25rem;
  padding-bottom: 0.46875rem;
  margin: 0 auto;
}
.using .using-content .using-body-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0;
  margin: 0;
  width: 100%;
}
.using .using-content .using-body-list .new-video {
  display: none;
  position: fixed;
  width: 6.25rem;
  height: 3.125rem;
  z-index: 99;
  top: 28%;
  left: 22.5%;
  background: #000000;
}
.using .using-content .using-body-list .new-video .new-video-box {
  position: relative;
}
.using .using-content .using-body-list .new-video .new-video-box > .video-react-controls-enabled {
  padding-top: 50% !important;
}
.using .using-content .using-body-list .new-video .new-video-box > .video-react-controls-enabled .video-react-big-play-button {
  display: none;
}
.using .using-content .using-body-list .new-video .new-video-box > .react-video {
  width: 100%;
  height: 100%;
}
.using .using-content .using-body-list .new-video .new-video-box > .video_play {
  width: 0.26042rem;
  height: 0.26042rem;
  background-image: url("/static/img/video_close.png");
  background-size: 100% 100%;
  position: absolute;
  top: -0.13021rem;
  right: -0.28646rem;
}
.using .using-content .using-body-list-item-link {
  width: 50%;
}
.using .using-content .using-body-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.02604rem;
  margin-bottom: 0.10938rem;
  height: 2.60417rem;
  overflow: hidden;
}
.using .using-content .using-body-list-item::before {
  content: ' ';
  display: table;
}
.using .using-content .using-body-list-item:hover {
  cursor: pointer;
}
.using .using-content .using-body-list-item:hover .tile-meida::after {
  background-color: rgba(0, 0, 0, 0.25);
}
.using .using-content .using-body-list-item:hover .tile-meida.video .play_video_btn {
  opacity: 1;
}
.using .using-content .using-body-list-item:hover .tile-meida .tile-meida-image .tile-meida-image-box .tile-meida-image-asset {
  background-size: auto 105%;
}
.using .using-content .using-body-list-item:hover .using-body-list-item-content {
  justify-content: flex-start;
}
.using .using-content .using-body-list-item:hover .tile-subhead {
  opacity: 1;
  margin-top: 0.08333rem;
  height: 0.25rem;
}
.using .using-content .using-body-list-item .new-box {
  width: 100%;
  height: 1.88542rem;
  position: relative;
}
.using .using-content .using-body-list-item .new-box > .video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.3125rem !important;
  height: 0.3125rem;
}
.using .using-content .using-body-list-item .tile-meida {
  position: relative;
  width: 100%;
}
.using .using-content .using-body-list-item .tile-meida .play_video_btn {
  position: absolute;
  width: 0.28646rem;
  height: 0.28646rem;
  background-image: url(/static/img/play_veido_btn.png);
  background-size: 0.26042rem 0.26042rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0.59896rem;
  z-index: 8;
  opacity: 0;
  transition: opacity 0.3s;
}
.using .using-content .using-body-list-item .tile-meida::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: background-color 250ms ease;
  z-index: 6;
}
.using .using-content .using-body-list-item .tile-meida .tile-meida-image .tile-meida-image-box {
  position: relative;
  width: 100%;
  height: 1.88542rem;
  transition: height 250ms ease;
}
.using .using-content .using-body-list-item .tile-meida .tile-meida-image .tile-meida-image-box .tile-meida-image-asset {
  position: absolute;
  top: 0;
  width: 100%;
  height: 1.88542rem;
  left: 0;
  background-size: auto 100%;
  transition: opacity 1s ease-out, transform 250ms ease, background-size 250ms ease;
  background-position: center center;
  background-repeat: no-repeat;
}
.using .using-content .using-body-list-item .new-img {
  height: 1.88542rem;
  overflow: hidden;
  border-radius: 0.02604rem;
}
.using .using-content .using-body-list-item .new-img > img {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 100%;
}
.using .using-content .using-body-list-item .tile-subhead {
  position: relative;
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 0.0625rem;
  line-height: 0.125rem;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  color: #6e6e73;
  margin: 0 0.125rem;
}
.using .using-content .using-body-list-item-content {
  position: relative;
  padding: 0.125rem;
  width: 100%;
  height: 0.78125rem;
  box-sizing: border-box;
  background-color: #fff;
  transition: transform 250ms ease;
}
.using .using-content .using-body-list-item-content .using-body-list-item-content-box {
  position: absolute;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.125rem 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 7;
}
.using .using-content .using-body-list-item-content .tile-head {
  padding: 0 0.125rem;
}
.using .using-content .using-body-list-item-content .tile-head .category-eyebrow {
  letter-spacing: 0em;
}
.using .using-content .using-body-list-item-content .tile-head .category-eyebrow > label {
  color: #0B97CF;
  border-color: #0B97CF;
  font-size: 12PX;
}
.using .using-content .using-body-list-item-content .tile-head .tile-content-headline {
  font-size: 0.10938rem;
  line-height: 1.19048;
  font-weight: 600;
  letter-spacing: 0.011em;
  margin-top: 0.02604rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: black;
}
.using .using-content .using-body-list-item-content .tile-date {
  padding: 0 0.125rem;
  margin-top: 0.08333rem;
  font-size: 12PX;
  line-height: 1.33337;
  font-weight: 400;
  color: #6e6e73;
  letter-spacing: 0em;
}
.using .using-content .using-body-list-item-content .new-type {
  font-size: 0.07292rem;
  color: #0B97CF;
  margin-bottom: 0.06771rem;
}
.using .using-content .using-body-list-item-content .new-title {
  color: #000000;
  font-size: 0.11458rem;
  font-weight: bold;
  line-height: 0.1875rem;
}
.using .using-content .using-body-list-item-content .new-time {
  font-size: 0.07292rem;
  color: #6B6B70;
  margin: 0.05729rem 0 0.04688rem;
}
.using .using-content .using-body-list-item-content .new-abstract {
  line-height: 0.14583rem;
  color: #6B6B70;
  transform: translate3d(0, 0.10417rem, 0);
}
.using .using-content .using-body-list-item-content .new-abstract > p {
  font-size: 0.07292rem;
  word-wrap: break-word;
}
.using .using-content .using-body-list-item:hover {
  box-shadow: 0 0.02083rem 0.16146rem 0.00521rem rgba(0, 0, 0, 0.14);
}
.using .using-content .using-body-list-item:hover.square .new-abstract {
  opacity: 1 !important;
}
.using .using-content .using-body-list-item:hover .new-img {
  animation: showAbstract 0.3s linear;
  -webkit-animation: showAbstract 0.3s linear;
}
@keyframes showAbstract {
  0% {
    height: 1.88542rem;
  }
  100% {
    height: 1.47917rem;
  }
}
@keyframes hiddenAbstract {
  0% {
    height: 1.47917rem;
  }
  100% {
    height: 1.88542rem;
  }
}
.using .using-content .using-body-list .rectangle {
  flex: 1;
  background: #ffffff;
  border-radius: 0.02604rem;
  margin-right: 0.11979rem;
  box-sizing: border-box;
}
.using .using-content .using-body-list .rectangle .new-abstract {
  margin-right: 0.43229rem;
}
.using .using-content .using-body-list .rectangle .new-abstract > span {
  font-size: 0.07292rem;
}
.using .using-content .using-body-list .rectangle2 {
  margin-right: auto;
  margin-left: 0.11979rem;
}
.using .using-content .using-body-list .square {
  background: #ffffff;
  border-radius: 0.02604rem;
  flex: 1;
}
.using .using-content .using-body-list .square .new-abstract {
  opacity: 0;
}
.using .using-content .using-body-list .square > .new-img > img {
  width: 100%;
}
.using .using-content .using-body-list .average-left {
  width: 3.14063rem;
  background: #ffffff;
  border-radius: 0.02604rem;
  margin-right: 0.11979rem;
}
.using .using-content .using-body-list .average-left .using-body-list-item-content {
  padding-right: 0.625rem;
}
.using .using-content .using-body-list .average-left > .new-img > img {
  width: 100%;
}
.using .using-content .using-body-list .average-right {
  width: 2.96875rem;
  background: #ffffff;
  border-radius: 0.02604rem;
}
.using .using-content .using-body-list .average-right > .new-img > img {
  width: 100%;
}
.using .using-content .white_more_mask {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2.64063rem;
  background-image: url(/static/img/white_mask_more.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 8;
  width: 100%;
}
.using .using-content .more {
  position: relative;
  color: #1078DB;
  font-size: 0.11458rem;
  text-align: center;
  padding-bottom: 0.40104rem;
  z-index: 9;
  cursor: pointer;
}
@keyframes abstractShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.coverflow-swiper-container-wrap {
  padding: 0.51042rem 0;
  position: relative;
  background: #F5F5F7;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container {
  position: relative;
  width: 8.17188rem;
  margin: 0 auto;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .coverflow-swiper-container-body {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-slide-shadow-left,
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-slide-shadow-right {
  background-image: linear-gradient(to left, rgba(229, 229, 229, 0.4), rgba(229, 229, 229, 0));
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide-prev,
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-no-swiping,
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide-duplicate-prev {
  transform: translate3d(0, 0, -0.52083rem) rotateX(0deg) rotateY(50deg) !important;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide-next {
  transform: translate3d(0, 0, -0.52083rem) rotateX(0deg) rotateY(-50deg) !important;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide {
  height: 100%;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-next,
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev {
  top: 0.625rem;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-card-title,
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-card-text {
  display: none;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card {
  background-color: #e5e5e5;
  overflow: hidden;
  cursor: pointer;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card .swiper-card-content {
  display: none;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card .tile-meida {
  position: relative;
  width: 100%;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card .tile-meida .play_video_btn {
  position: absolute;
  width: 0.28646rem;
  height: 0.28646rem;
  background-image: url(/static/img/play_veido_btn.png);
  background-size: 0.26042rem 0.26042rem;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0.70313rem;
  z-index: 8;
  opacity: 0;
  transition: opacity 0.3s;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card .tile-meida::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: background-color 250ms ease;
  z-index: 6;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card .tile-meida .tile-meida-image .tile-meida-image-box {
  position: relative;
  width: 5.20833rem;
  height: 2.92969rem;
  transition: height 250ms ease;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card .tile-meida .tile-meida-image .tile-meida-image-box .tile-meida-image-asset {
  position: absolute;
  top: 0;
  width: 5.20833rem;
  height: 2.92969rem;
  left: 0;
  background-size: 100% auto;
  transition: opacity 1s ease-out, transform 250ms ease, background-size 250ms ease;
  background-position: center center;
  background-repeat: no-repeat;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active {
  width: auto !important;
  transform: translate3d(0, 0, 0) rotateX(0deg) rotateY(0deg) !important;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-card-title {
  display: block;
  color: #333333;
  font-size: 0.1875rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 0.21875rem;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-card-title-line {
  display: flex;
  margin-top: 0.05208rem;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-card-text {
  display: inline-block;
  margin-right: 0.08333rem;
  font-size: 0.08333rem;
  color: #666666;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card {
  background: #ffffff;
  border-radius: 0.07813rem;
  padding: 0.08333rem;
  padding-bottom: 0;
  position: relative;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card:hover .tile-meida::after {
  background-color: rgba(0, 0, 0, 0.25);
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card:hover .tile-meida.video .play_video_btn {
  opacity: 1;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card:hover .tile-meida .tile-meida-image .tile-meida-image-box .tile-meida-image-asset {
  background-size: 105% auto;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card:hover .swiper-card-content .swiper-card-content-box .swiper-tile-subhead {
  opacity: 1;
  margin-top: 0.08333rem;
  height: 0.29167rem;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card .swiper-card-content {
  display: block;
  position: relative;
  width: 100%;
  height: 0.52083rem;
  box-sizing: border-box;
  background-color: #fff;
  transition: transform 250ms ease;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card .swiper-card-content .swiper-card-content-box {
  position: absolute;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.21354rem 0.06771rem;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 7;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card .swiper-card-content .swiper-card-content-box .swiper-tile-subhead {
  position: relative;
  opacity: 0;
  height: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.08333rem;
  line-height: 0.14583rem;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  color: #6e6e73;
  padding: 0;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card .coverflow-swiper-img {
  width: 5.07813rem;
  height: 2.28125rem;
  border-radius: 0.07813rem 0.07813rem 0 0;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .swiper-slide-card .video-play {
  width: 0.3125rem;
  height: 0.3125rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .video-play {
  width: 0.3125rem;
  height: 0.3125rem;
  position: absolute;
  top: 40%;
  left: 32%;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev .video-play {
  left: 64%;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .coverflow-swiper-img,
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .coverflow-swiper-slider-video {
  width: 5.07813rem;
  height: 2.28125rem;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .coverflow-swiper-slider-video {
  position: relative;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .coverflow-swiper-slider-video .new-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 0.07813rem 0.07813rem 0 0;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .coverflow-swiper-slider-video .coverflow-swiper-video {
  width: 5.07813rem;
  height: 2.28125rem;
  display: none;
}
.coverflow-swiper-container-wrap .coverflow-swiper-container .swiper-wrapper .swiper-slide .coverflow-swiper-slider-video .coverflow-swiper-video > video {
  width: 100%;
  height: 100%;
  background: #000000;
  border-radius: 0.07813rem 0.07813rem 0 0;
}
.coverflow-swiper-container-wrap .coverflow-swiper-button {
  outline: none;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  bottom: 0;
  z-index: 5;
  cursor: pointer;
  opacity: 0.8;
  width: 0.27083rem;
  height: 0.27083rem;
  border: 0.01042rem solid #00aaee;
  border-radius: 50%;
}
.coverflow-swiper-container-wrap .coverflow-swiper-button:hover {
  background: #00a2ff;
}
.coverflow-swiper-container-wrap .coverflow-swiper-button:before {
  content: "";
  width: 0.08333rem;
  height: 0.08333rem;
  position: absolute;
  border-left: 0.01042rem solid #00AAEE;
  border-bottom: 0.01042rem solid #00AAEE;
}
.coverflow-swiper-container-wrap .coverflow-swiper-button:hover:before {
  border-left: 0.01042rem solid #fff;
  border-bottom: 0.01042rem solid #fff;
}
.coverflow-swiper-container-wrap .coverflow-swiper-button.coverflow-swiper-button-prev {
  left: -0.5625rem;
  right: auto;
}
.coverflow-swiper-container-wrap .coverflow-swiper-button.coverflow-swiper-button-prev::before {
  top: 50%;
  left: 40%;
  -webkit-transform: translate(0, -50%) rotate(40deg);
  transform: translate(0, -50%) rotate(40deg);
}
.coverflow-swiper-container-wrap .coverflow-swiper-button.coverflow-swiper-button-next {
  right: -0.5625rem;
  left: auto;
}
.coverflow-swiper-container-wrap .coverflow-swiper-button.coverflow-swiper-button-next::before {
  top: 50%;
  right: 40%;
  -webkit-transform: translate(0, 50%) rotate(-135deg);
  transform: translate(0, -50%) rotate(-135deg);
}
.coverflow-new-video {
  position: fixed;
  width: 6.25rem;
  height: 3.125rem;
  z-index: 99;
  top: 28%;
  margin: 0 auto;
  left: 0;
  right: 0;
  background: #000000;
}
.coverflow-new-video .new-video-box {
  position: relative;
}
.coverflow-new-video .new-video-box > .video-react-controls-enabled {
  padding-top: 50% !important;
}
.coverflow-new-video .new-video-box > .video-react-controls-enabled .video-react-big-play-button {
  display: none;
}
.coverflow-new-video .new-video-box > .react-video {
  width: 100%;
  height: 100%;
}
.coverflow-new-video .new-video-box > .video_play {
  width: 0.26042rem;
  height: 0.26042rem;
  background-image: url("/static/img/video_close.png");
  background-size: 100% 100%;
  position: absolute;
  top: -0.13021rem;
  right: -0.28646rem;
}

.card-swiper-container-wrap {
  padding: 0.30208rem 0;
  position: relative;
}
.card-swiper-container-wrap .card-swiper-container {
  position: relative;
  width: 6.25rem;
  margin: 0 auto;
}
.card-swiper-container-wrap .card-swiper-container .card-swiper-pagination {
  position: absolute;
  text-align: center;
  bottom: 0.13542rem;
  z-index: 5;
}
.card-swiper-container-wrap .card-swiper-container .card-swiper-pagination .swiper-pagination-bullet {
  width: 0.04688rem;
  height: 0.04688rem;
  margin: 0 0.0625rem;
  border: 0.01042rem solid #ffffff;
  opacity: 1;
  outline: none;
  background: transparent;
}
.card-swiper-container-wrap .card-swiper-container .card-swiper-pagination .swiper-pagination-bullet-active {
  background: #FFF;
  box-shadow: 0 0 0.01042rem 0.01042rem rgba(255, 255, 255, 0.6);
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide {
  height: 100%;
  position: relative;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .swiper-slide-card {
  background: #FFF;
  height: 3.51563rem;
  box-shadow: 0 0.02604rem 0.05208rem 0 rgba(0, 112, 192, 0.06);
  border-radius: 0.07813rem;
  padding: 0.09375rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-img,
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video {
  width: 100%;
  height: auto;
  max-height: 3.51563rem;
  border-radius: 0.07813rem;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video > .new-box {
  width: 100%;
  height: 3.51563rem;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video > .new-box .new-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 0.07813rem;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video > .new-box .video-play {
  width: 0.3125rem;
  height: 0.3125rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video > .new-box:hover {
  cursor: pointer;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video > .new-box:hover .video-play {
  opacity: 1;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video .swiper-slide-card-video {
  width: 100%;
  height: 3.51563rem;
  display: none;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video .swiper-slide-card-video > video {
  width: 100%;
  height: auto;
  background: #000000;
  border-radius: 0.07813rem;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video .video-react {
  border-radius: 0.07813rem;
  padding-top: 35% !important;
}
.card-swiper-container-wrap .card-swiper-container .swiper-wrapper .swiper-slide .card-swiper-video .video-react > .video-react-poster {
  border-radius: 0.07813rem;
}
.card-swiper-container-wrap .card-swiper-button {
  outline: none;
  top: 1.81771rem;
  position: absolute;
  bottom: 0;
  z-index: 5;
  cursor: pointer;
  opacity: 0.8;
  width: 0.27083rem;
  height: 0.27083rem;
  border: 0.01042rem solid #00aaee;
  border-radius: 50%;
}
.card-swiper-container-wrap .card-swiper-button:hover {
  background: #00a2ff;
}
.card-swiper-container-wrap .card-swiper-button:before {
  content: "";
  width: 0.08333rem;
  height: 0.08333rem;
  position: absolute;
  border-left: 0.01042rem solid #00AAEE;
  border-bottom: 0.01042rem solid #00AAEE;
}
.card-swiper-container-wrap .card-swiper-button:hover:before {
  border-left: 0.01042rem solid #fff;
  border-bottom: 0.01042rem solid #fff;
}
.card-swiper-container-wrap .card-swiper-button.card-swiper-button-prev {
  left: -0.625rem;
  top: 50%;
  transform: translateY(-50%);
  right: auto;
}
.card-swiper-container-wrap .card-swiper-button.card-swiper-button-prev::before {
  top: 50%;
  left: 40%;
  -webkit-transform: translate(0, -50%) rotate(40deg);
  transform: translate(0, -50%) rotate(40deg);
}
.card-swiper-container-wrap .card-swiper-button.card-swiper-button-next {
  right: -0.625rem;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
}
.card-swiper-container-wrap .card-swiper-button.card-swiper-button-next::before {
  top: 50%;
  right: 40%;
  -webkit-transform: translate(0, 50%) rotate(-135deg);
  transform: translate(0, -50%) rotate(-135deg);
}

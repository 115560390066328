.experience .experience-head {
  background: #000;
  height: 0.26042rem;
}
.experience .experience-banner-test {
  font-size: 0.08333rem;
  color: #000000;
}
.experience .experience-banner-one {
  padding-top: 0.52083rem;
  text-align: center;
  position: relative;
}
.experience .experience-banner-one .common-flex > img {
  height: 0.35938rem;
  width: 2.52083rem;
  margin-top: 0.71875rem;
}
.experience .experience-banner-one-top {
  height: 10.26042rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fexperience-1@2x.png");
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}
.experience .experience-banner-one-btn {
  width: 1.61458rem;
  height: 0.25rem;
  background: linear-gradient(-90deg, #0bfc6e, #63def5);
  border-radius: 0.125rem;
  color: #ffffff;
  font-size: 0.10417rem;
  margin: 0.20313rem auto;
}
.experience .experience-banner-one .common-title-sub {
  color: #000000;
  font-size: 0.3125rem;
  font-weight: bold;
}
.experience .video-bg {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fexperience-bg@2x.png");
  background-size: cover;
}
.experience .experience-banner-two {
  padding: 0.72917rem 0 1.26042rem 0;
  position: relative;
}
.experience .experience-banner-two > .device-img {
  display: block;
  width: 1.78125rem;
  height: 0.23958rem;
  margin: 0 auto;
  margin-bottom: 0.05208rem;
}
.experience .experience-banner-two .video-title {
  font-size: 0.33333rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}
.experience .experience-banner-two .video-wrap {
  padding-top: 0.44271rem;
}
.experience .experience-banner-two .video-wrap .video-react-play-progress:before {
  top: -0.45em;
}
.experience .experience-banner-two .video-wrap .video-react-progress-control:hover .video-react-play-progress:before {
  top: -0.33333333em;
}
.experience .experience-banner-two .video-wrap .video-react-big-play-button {
  display: none;
}
.experience .experience-banner-two .video-wrap .video-box {
  position: relative;
  width: 6.25rem;
  height: 3.125rem;
  border-radius: 0.07813rem;
  margin: 0 auto;
}
.experience .experience-banner-two .video-wrap .video-box .video-box-mask {
  position: absolute;
  width: 100%;
  height: 2.96875rem;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  cursor: pointer;
}
.experience .experience-banner-two .video-wrap .video-box .video-box-mask .video-box-play-btn {
  width: 0.28646rem;
  height: 0.28646rem;
}
.experience .experience-banner-two .video-wrap .video-box .video-react {
  border-radius: 0.07813rem;
  padding-top: 0 !important;
  height: 3.125rem;
}
.experience .experience-banner-two .video-wrap .video-box .video-react .video-react-big-play-button {
  width: 0.41667rem;
  height: 0.41667rem;
  border-radius: 50%;
  background: none;
  border: 0.01042rem solid #00aaee;
}
.experience .experience-banner-two .video-wrap .video-box .video-react .video-react-big-play-button::before {
  top: 0.05208rem;
  font-size: 0.23438rem;
  color: #00aaee;
}
.experience .experience-banner-two .video-wrap .video-box .video-react .video-react-video {
  border-radius: 0.07813rem;
}
.experience .experience-banner-two .video-wrap .video-box .video-react-has-started .video-react-control-bar {
  border-radius: 0 0 0.07813rem 0.07813rem;
}
.experience .experience-banner-three-body {
  width: 8.80208rem;
  padding-bottom: 0.36458rem;
  position: relative;
  margin: 0 auto;
}
.experience .experience-banner-three-body .shadow {
  width: 100%;
  height: 100%;
  background: #985AED;
  opacity: 0.83;
  position: absolute;
}
.experience .experience-banner-three-top {
  padding: 0.72917rem 0.41667rem;
  justify-content: space-between;
}
.experience .experience-banner-three-top-right {
  width: 1.53646rem;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
}
.experience .experience-banner-three-top-right-item {
  width: 0.71354rem;
  height: 0.71354rem;
  border-radius: 0.10417rem;
  margin-bottom: 0.10417rem;
  z-index: 1;
}
.experience .experience-banner-three-top-right-item > img {
  width: 100%;
  height: 100%;
}
.experience .experience-banner-three-top-left .experience-banner-logo .logo5 {
  width: 2.05729rem;
  height: 0.29688rem;
}
.experience .experience-banner-three-top .experience-banner-logo {
  z-index: 1;
}
.experience .experience-banner-three-top .experience-banner-test {
  z-index: 1;
  font-size: 0.41667rem;
  font-weight: 800;
  color: #ffffff;
  margin: 0.07292rem 0 0.22917rem 0;
}
.experience .experience-banner-three-top .experience-banner-test-small {
  font-size: 0.10417rem;
  color: #ffffff;
  z-index: 1;
}
.experience .experience-banner-three-list {
  z-index: 99;
}
.experience .experience-banner-three-list-item {
  padding-bottom: 0.44271rem;
  position: relative;
  height: 2.44792rem;
  background-size: cover;
}
.experience .experience-banner-three-list-item .left {
  width: 2.92708rem;
  height: 2.17708rem;
  background: #FFFFFF;
  padding: 0.44271rem 0 0 0.44271rem;
  position: absolute;
  left: 0;
  top: 0.13021rem;
  box-sizing: border-box;
  transition: all 0.8s linear;
}
.experience .experience-banner-three-list-item .left .location {
  font-size: 0.23958rem;
  color: #2B2B2B;
  font-weight: 800;
  display: flex;
  position: relative;
}
.experience .experience-banner-three-list-item .left .location .dingwei-icon {
  display: inline-block;
  width: 0.22917rem;
  height: 0.33854rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fhongkong.png");
  background-size: 100% 100%;
  margin-right: 0.06771rem;
  z-index: 99;
}
.experience .experience-banner-three-list-item .left .location .dingwei-icon.hk-icon {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fhongkong.png");
}
.experience .experience-banner-three-list-item .left .location .dingwei-icon.dj-icon {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fdongjing.png");
}
.experience .experience-banner-three-list-item .left .location .dingwei-icon.tw-icon {
  background-image: url("/static/img/taiwang.png");
}
@keyframes ripple {
  0% {
    top: 0.22396rem;
    left: 0.07813rem;
    width: 0.0625rem;
    height: 0.0625rem;
  }
  100% {
    left: -0.02604rem;
    top: 0.13021rem;
    opacity: 0.05;
    width: 0.25rem;
    height: 0.25rem;
  }
}
.experience .experience-banner-three-list-item .left .tips {
  width: 2.09375rem;
  height: 0.32292rem;
  border: 0.00521rem #985AED solid;
  margin: 0.11458rem 0;
  font-size: 0.14583rem;
  color: #985AED;
  text-align: center;
  line-height: 0.32292rem;
}
.experience .experience-banner-three-list-item .left .join {
  font-size: 0.17708rem;
  width: 2.39583rem;
}
.experience .experience-banner-three-list-item .left1 {
  transform: translateX(0.44271rem);
}
.experience .experience-banner-three-list-item.one .right {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fexperience-5.png");
}
.experience .experience-banner-three-list-item.two .right {
  left: 0;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fexperience-6.png");
}
.experience .experience-banner-three-list-item.two .left {
  left: auto;
  right: 0;
}
.experience .experience-banner-three-list-item.three .right {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fexperience-7.png");
}
.experience .experience-banner-three-list-item .right {
  width: 5.16667rem;
  height: 2.44792rem;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  transition: all 0.8s linear;
}
.experience .experience-banner-three-list-item .right1 {
  transform: translateX(-0.44271rem);
}
.experience .experience-banner-four {
  height: 4.79167rem;
  color: #FFFFFF;
  text-align: center;
  padding-top: 0.88542rem;
  box-sizing: border-box;
  position: relative;
}
.experience .experience-banner-four-text1 {
  font-size: 0.33333rem;
  font-weight: 800;
}
.experience .experience-banner-four-text2 {
  font-size: 0.16667rem;
  margin: 0.125rem 0 0.07292rem 0;
}
.experience .experience-banner-four-text3 {
  font-size: 0.08333rem;
  margin-bottom: 0.41667rem;
}
.experience .experience-banner-four-list-item {
  color: #454545;
  width: 1.19792rem;
  height: 1.41667rem;
  border-radius: 0.07813rem;
  margin-right: 0.06771rem;
  align-items: center;
  box-shadow: 0 0.05208rem 0.07292rem 0 rgba(102, 102, 102, 0.1);
}
.experience .experience-banner-four-list-item .pay-img {
  font-size: 0.11458rem;
  color: #ccc;
  height: 0.41667rem;
  line-height: 0.41667rem;
}
.experience .experience-banner-four-list-item .pay-img > img {
  width: 0.41667rem;
  height: 0.41667rem;
}
.experience .experience-banner-four-list-item .pay-name {
  margin: 0.14583rem 0 0.11458rem 0;
  font-size: 0.11458rem;
}
.experience .experience-banner-four-list-item .common-btn-bd,
.experience .experience-banner-four-list-item .common-btn-bg {
  width: 0.58854rem;
  height: 0.25rem;
  border-radius: 0.125rem;
}
.experience .experience-banner-four-list-item .common-btn-bd > button,
.experience .experience-banner-four-list-item .common-btn-bg > button {
  width: 0.58854rem;
  height: 0.25rem;
  border-radius: 0.125rem;
}
.experience .experience-banner-four-list-item .common-btn-bg {
  background: linear-gradient(90deg, #73f3ff, #6032f9, #c317ff);
}
.experience .experience-banner-four-list-item .flipped {
  transform-style: preserve-3d;
  transition: transform 1s;
}
.experience .experience-banner-four-list-item .front {
  width: 1.19792rem;
  height: 1.41667rem;
  background: #ffffff;
  border-radius: 0.07813rem;
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  align-items: center;
}
.experience .experience-banner-four-list-item .back {
  width: 1.19792rem;
  height: 1.41667rem;
  background: #ffffff;
  border-radius: 0.07813rem;
  position: absolute;
  align-items: center;
  backface-visibility: hidden;
  transform: rotateY(-180deg);
}
.experience .experience-banner-five {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fexperience-8.png");
  background-size: cover;
  height: 4.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-left: 1.05729rem;
  margin-bottom: 0.17708rem;
}
.experience .experience-banner-five .common-title {
  color: #ffffff;
  margin-bottom: 0.13542rem;
}
.experience .experience-banner-five-logo {
  color: #ffffff;
  display: flex;
  font-size: 0.29167rem;
  align-items: center;
  margin-bottom: 0.13542rem;
  font-weight: 200;
}
.experience .experience-banner-five-logo > img {
  width: 1.86458rem;
  height: 0.3125rem;
  margin-right: 0.07813rem;
  margin-top: 0.05208rem;
}
.experience .experience-banner-five-text {
  font-size: 0.34375rem;
  color: #ffffff;
  opacity: 1;
  line-height: 0.44271rem;
}
.experience .experience-banner-five .change-text {
  transform: translate(-50%, -50%);
  animation: change 5s;
  -moz-animation: change 5s;
  -webkit-animation: change 5s;
  -o-animation: change 5s;
  opacity: 1;
}
@keyframes change {
  from {
    font-size: 0.0625rem;
    opacity: 0;
    bottom: 0;
  }
  to {
    font-size: 0.34375rem;
    opacity: 1;
    bottom: 1.66667rem;
  }
}
.experience .experience-banner-five-btn {
  width: 0.64583rem;
  height: 0.25rem;
  background: #ffffff;
  color: #454545;
  box-shadow: 0 0.02604rem 0.03125rem 0 rgba(0, 168, 255, 0.24);
  border-radius: 0.125rem;
  font-size: 0.09375rem;
  margin-top: 0.20833rem;
}

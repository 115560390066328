.home-banner-six {
  margin: 0 auto;
  margin: 0.10417rem 0 0 0;
  padding: 0.52083rem 0;
  background: linear-gradient(-90deg, #131844, #0f0c3a);
  position: relative;
  overflow: hidden;
}
.home-banner-six.home-banner-six-selected {
  background: #00ECFF;
}
.home-banner-six .country-info-name {
  font-size: 0.08333rem;
}
.home-banner-six .country-info-target {
  font-size: 0.0625rem;
}
.home-banner-six .home-banner-six-map {
  position: relative;
}
.home-banner-six .selected-map {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 5;
  background-image: url([object Module]);
}
.home-banner-six .selected-map.show-selected-map {
  display: block;
  animation: show 2s linear;
  -webkit-animation: show 2s linear;
}
@keyframes show {
  0% {
    transform: scale(0.00001);
  }
  100% {
    transform: scale(1);
  }
}
.home-banner-six .selected-map .selected-map-info-wrap {
  position: relative;
  height: 4.19271rem;
  margin: 0.46875rem 1.40625rem;
  padding-top: 0.38542rem;
}
.home-banner-six .selected-map-info {
  position: relative;
  display: none;
  color: #FFFFFF;
  text-align: center;
  width: 1.73438rem;
  height: 1.01042rem;
  background: #00dbf1;
  box-shadow: 0 0.01042rem 0.05729rem 0.00521rem rgba(157, 157, 157, 0.14);
  border-radius: 0.05208rem;
  padding-top: 0.10938rem;
  box-sizing: border-box;
  margin-left: 0.57292rem;
  z-index: 8;
}
.home-banner-six .selected-map-info-logo {
  align-items: center;
  z-index: 9;
}
.home-banner-six .selected-map-info-logo > img {
  width: 0.84375rem;
  height: 0.14583rem;
  margin-top: 0.02604rem;
}
.home-banner-six .selected-map-info-logo > span {
  font-size: 0.13542rem;
}
.home-banner-six .selected-map-info-count {
  font-size: 0.375rem;
  margin-bottom: 0.01042rem;
}
.home-banner-six .selected-map-info-all {
  width: 1.73438rem;
  height: 0.30729rem;
  background: #C2F6FC;
  border-radius: 0 0 0.05208rem 0.05208rem;
  color: #707070;
  text-align: left;
  align-items: center;
}
.home-banner-six .selected-map-info-all .country-emblem {
  width: 0.22917rem;
  height: 0.14583rem;
  margin-right: 0.11979rem;
}
.home-banner-six .selected-map .selected-point-list {
  position: relative;
  color: #000;
  margin: 0.20833rem 0 0 2.25rem;
}
.home-banner-six .selected-map .selected-point-list-item {
  position: absolute;
  width: 0.10417rem;
  height: 0.14063rem;
  background-size: 100% 100%;
  background-image: url("/static/img/point@2x.png");
}
.home-banner-six .selected-map .selected-point-list-country {
  position: absolute;
  display: flex;
  padding-left: 0.08333rem;
  padding-right: 0.15625rem;
  height: 0.36458rem;
  background: #ffffff;
  box-shadow: 0 0.01042rem 0.03125rem 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.18229rem;
  transition: all 0.6s linear;
  top: 0.75521rem;
  left: 0.52083rem;
  align-items: center;
  z-index: 4;
}
.home-banner-six .selected-map .selected-point-list-country.Taiwan {
  top: 0.75521rem;
  left: 0.46875rem;
}
.home-banner-six .selected-map .selected-point-list-country.JAPAN {
  top: 0.72917rem;
  left: 0.39063rem;
}
.home-banner-six .selected-map .selected-point-list-country.Thailand {
  top: 0.72917rem;
  left: 0.49479rem;
}
.home-banner-six .selected-map .selected-point-list-country.JAPAN {
  top: 0.72917rem;
  left: 0.41667rem;
}
.home-banner-six .selected-map .selected-point-list-country.Macau {
  top: 0.78125rem;
  left: 0.20833rem;
}
.home-banner-six .selected-map .selected-point-list-country.KOREA {
  top: 0.83333rem;
  left: 0.26042rem;
}
.home-banner-six .selected-map .selected-point-list-country.Singapore {
  top: 0.70313rem;
  left: 0.46875rem;
}
.home-banner-six .selected-map .selected-point-list-country.Malaysia {
  top: 0.75521rem;
  left: 0.46875rem;
}
.home-banner-six .selected-map .selected-point-list-country.Indonesia {
  top: 0.78125rem;
  left: 0.15625rem;
}
.home-banner-six .selected-map .selected-point-list-country > .left {
  width: 0.27083rem;
  height: 0.32813rem;
  margin-right: 0.05208rem;
}
.home-banner-six .selected-map .selected-point-list-country > .left > img {
  width: 0.27083rem;
  height: 0.32813rem;
}
.home-banner-six .selected-map .selected-point-list-country .right {
  text-align: left;
}
.home-banner-six .selected-map .selected-point-list-country .right-name {
  font-size: 0.07813rem;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0.02604rem;
  white-space: nowrap;
}
.home-banner-six .selected-map .selected-point-list-country .right-count {
  font-size: 0.125rem;
  background: linear-gradient(90deg, #40fdfb 0%, #27f03c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
}
.home-banner-six-map {
  height: 4.19271rem;
  border: 0.00521rem solid #002e82;
  border-radius: 0.05208rem;
  z-index: 8;
  position: absolute;
  margin: 0 1.40625rem;
  left: auto;
  right: auto;
  display: flex;
  overflow: hidden;
  /*定义滚动条轨道 内阴影*/
  /*定义滑块 内阴影*/
}
.home-banner-six-map .country_back_btn {
  display: none;
  border: 0.00521rem solid #00ECFF;
  border-radius: 50%;
  width: 0.26042rem;
  height: 0.26042rem;
  position: absolute;
  top: 0.05208rem;
  left: 0.05208rem;
  font-size: 0.125rem;
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fback-icon-blue.png);
  background-size: auto 60%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 77;
  transition: all 0.3;
}
.home-banner-six-map .country_back_btn:hover {
  background-color: #00ECFF;
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fback-icon-white.png);
}
.home-banner-six-map ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.03125rem rgba(0, 46, 130, 0.3);
  -webkit-box-shadow: inset 0 0 0.03125rem rgba(0, 46, 130, 0.3);
  background-color: #FFF;
  border-radius: 0.03125rem;
  -webkit-border-radius: 0.03125rem;
}
.home-banner-six-map ::-webkit-scrollbar {
  width: 0.0625rem;
  /* 纵向滚动条*/
  background-color: #FFF;
  border-radius: 0.03125rem;
  -webkit-border-radius: 0.03125rem;
}
.home-banner-six-map ::-webkit-scrollbar-corner {
  border-radius: 0.03125rem;
}
.home-banner-six-map ::-webkit-scrollbar-thumb {
  position: relative;
  border-radius: 0.03125rem;
  box-shadow: inset 0 0 0.03125rem rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 0.03125rem rgba(0, 0, 0, 0);
  background-color: #002E82;
}
.home-banner-six-map.select-map-box {
  border: 0.00521rem solid #f2f2f2;
  border-right: none;
  border-radius: 0.05208rem;
  transition: all 1s;
  /*定义滑块 内阴影*/
}
.home-banner-six-map.select-map-box ::-webkit-scrollbar-thumb {
  position: relative;
  border-radius: 0.03125rem;
  box-shadow: inset 0 0 0.03125rem rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 0.03125rem rgba(0, 0, 0, 0);
  background-color: #f0e8e8;
}
.home-banner-six-map .left {
  position: relative;
  font-size: 0.4375rem;
  width: 3.28646rem;
  height: 100%;
  margin: 0 0 0 1.16146rem;
  z-index: 2;
}
.home-banner-six-map .left-box {
  width: 3.28646rem;
  height: 100%;
  background-image: url("/static/img/map@2x.png");
  background-size: 100% 100%;
  color: #ffffff;
  box-sizing: border-box;
  position: relative;
}
.home-banner-six-map .left-box.hidden-select-map {
  display: none;
  animation: hidden 2s linear;
  -webkit-animation: hidden 2s linear;
}
@keyframes hidden {
  0% {
    transform: scale(0.00001);
  }
  100% {
    transform: scale(0.00001);
  }
}
.home-banner-six-map .left-box .left-top {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.45313rem;
  left: -0.42708rem;
}
.home-banner-six-map .left-box .left-top .power {
  border: 0.01042rem solid #ffffff;
  padding: 0.01563rem;
  box-sizing: border-box;
  width: 0.36458rem;
  height: 0.40625rem;
  margin-right: 0.10417rem;
}
.home-banner-six-map .left-box .point-list-item {
  position: absolute;
}
.home-banner-six-map .left-box .point-list-item > .point-icon {
  width: 0.125rem;
  height: 0.16146rem;
  background-image: url("/static/img/point2@2x.png");
  background-size: 100% 100%;
  margin-right: 0.04688rem;
  transition: transform 0.3s linear;
}
@keyframes hove {
  from {
    width: 0.23438rem;
    height: 0.27083rem;
    transform: translateY(-0.13021rem) translateX(-0.05208rem);
  }
  to {
    width: 0.125rem;
    height: 0.16146rem;
    transform: translateY(0) translateX(0);
  }
}
.home-banner-six-map .left-box .point-list-item > .place-name {
  font-size: 0.08333rem;
  margin-left: 0.05208rem;
}
.home-banner-six-map .left-box .point-list-item.hover > .point-icon {
  transform: scale(2) translateY(-0.04688rem) translateX(0);
}
@keyframes hoved {
  from {
    width: 0.125rem;
    height: 0.17188rem;
    transform: translateY(0) translateX(0);
  }
  to {
    width: 0.23438rem;
    height: 0.28646rem;
    transform: translateY(-0.13021rem) translateX(-0.05208rem);
  }
}
.home-banner-six-map .left-box .point-list-item.hover > .place-name {
  font-size: 0.08333rem;
}
@keyframes placeName {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-0.11458rem);
  }
}
.home-banner-six-map .left-box .point-list-item:nth-child(1) {
  left: 1.28646rem;
  top: 1.63542rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(2) {
  left: 1.52604rem;
  top: 1.48438rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(3) {
  left: 0.29167rem;
  top: 2.1875rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(4) {
  left: 0.53125rem;
  top: 1.53125rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(5) {
  left: 2.48958rem;
  top: 0.67708rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(6) {
  left: 0.4375rem;
  top: 3.21875rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(7) {
  left: 0.29167rem;
  top: 2.89063rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(8) {
  left: 0.3125rem;
  top: 2.5rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(9) {
  left: 0.43229rem;
  top: 2.78125rem;
}
.home-banner-six-map .left-box .point-list-item:nth-child(10) {
  left: 1.35417rem;
  top: 3.03646rem;
}
.home-banner-six-map .left-box .home-banner-six-logo {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.98958rem;
  left: -0.42708rem;
}
.home-banner-six-map .left-box .home-banner-six-logo > img {
  width: 1.38021rem;
  height: 0.20833rem;
  margin-top: 0.02604rem;
}
.home-banner-six-map .left-box .home-banner-six-logo > span {
  font-size: 0.1875rem;
}
.home-banner-six-map .right {
  position: absolute;
  right: -0.00521rem;
  height: 3.88021rem;
  padding: 0.15625rem 0.47917rem 0.15625rem 0.05208rem;
  color: #ffffff;
  border-radius: 0.07813rem;
  z-index: 8;
  margin-left: auto;
  overflow-y: scroll;
  width: 2.11458rem;
}
.home-banner-six-map .right .home-banner-six-map-list {
  border-radius: 0.07813rem;
}
.home-banner-six-map .right .home-banner-six-map-item {
  border-radius: 0.26042rem;
  transition: transform 0.5s linear;
  -webkit-transition: transform 0.5s linear;
}
.home-banner-six-map .right .home-banner-six-map-item:not(:last-child) {
  margin-bottom: 0.16667rem;
}
.home-banner-six-map .right .home-banner-six-map-item:hover {
  transform: translate3d(0, -0.02083rem, 0);
}
.home-banner-six-map .right .home-banner-six-map-item:hover .img-box > .bgImg {
  height: 1.00521rem;
}
.home-banner-six-map .right .all {
  width: 1.71354rem;
  height: 1rem;
  overflow: hidden;
  border-radius: 0.07813rem;
}
.home-banner-six-map .right .img-box {
  width: 1.71354rem;
  height: 1.14583rem;
  border-radius: 0.07813rem;
  font-size: 0.25rem;
  position: relative;
  text-align: center;
  align-items: center;
}
.home-banner-six-map .right .img-box > div,
.home-banner-six-map .right .img-box button {
  z-index: 3;
}
.home-banner-six-map .right .img-box > .bgImg {
  width: 1.71354rem;
  height: 1.17188rem;
  border-radius: 0.07813rem;
  position: absolute;
  top: 0;
  left: 0;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.4s;
}
.home-banner-six-map .right .img-box .common-btn {
  width: 0.36458rem;
  height: 0.16667rem;
  border: 0.00521rem solid #ffffff;
  border-radius: 0.07813rem;
  font-size: 0.09375rem;
  margin-top: 0.10417rem;
}
.home-banner-six-map .right .img-box .common-btn:hover {
  border: none;
}
.home-banner-six-map .right .name-box {
  text-align: center;
  color: #000000;
  width: 0.5rem;
  background: #ffffff;
  border-radius: 0 0.07813rem 0.07813rem 0;
}
.home-banner-six-map .right .name-box > img {
  width: 0.28125rem;
  height: 0.17708rem;
  margin: 0 auto;
  transition: 1s all linear;
  -webkit-transition: 1s all linear;
}
.home-banner-six-map .right .name-box .country {
  font-size: 0.0625rem;
  margin: 0.02604rem 0;
  font-weight: bold;
}
.home-banner-six-map .right .name-box .country > span {
  font-size: 0.0625rem;
  display: block;
  margin-top: 0.02083rem;
  font-weight: normal;
}
.home-banner-six .swiper-container {
  width: 6.25rem;
  margin-top: 0.38542rem;
}
.home-banner-six .swiper-container .swiper-pagination {
  bottom: 0.26042rem;
}
.home-banner-six .swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 0.07813rem;
  height: 0.07813rem;
  margin: 0 0.05208rem !important;
}
.home-banner-six .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #ffffff;
}
.home-banner-six .swiper-container .swiper-wrapper .swiper-slide {
  width: 5.20833rem;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 2.69271rem;
}
.home-banner-six .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body {
  width: 6.14583rem;
  margin: 0 auto;
}
.home-banner-six .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body-flex {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 0.76563rem;
}
.svg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5.25rem;
  padding: 0;
  overflow: hidden;
  padding-right: 1.40625rem;
  z-index: 5;
}
.svg-wrapper .svg-container {
  padding: 0;
  width: 100%;
  height: 5.25rem;
}
.svg-wrapper .svg-wrapper-rect {
  width: 100%;
  height: 5.25rem;
}
.svg-wrapper .svg-wrapper-img {
  position: relative;
  height: 5.25rem;
  left: 0;
  z-index: 3;
  top: 0;
  opacity: 1;
}
.visible #cd-image-1 circle {
  animation: visible-clippath 2.8s linear forwards;
}
.unvisible #cd-image-1 circle {
  animation: visible-clippath-back 1s linear forwards;
}
@keyframes visible-clippath {
  100% {
    r: 2400;
  }
}
@keyframes visible-clippath-back {
  0% {
    r: 1200;
  }
  100% {
    r: 0;
  }
}
.dot:before {
  content: ' ';
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 0.02604rem;
  height: 0.02604rem;
  background: linear-gradient(-90deg, #40fdfb, #27f03c);
  opacity: 0.2;
  border-radius: 50%;
  opacity: 0;
}
.dot:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  background: linear-gradient(90deg, #40fdfb, #27f03c);
  opacity: 0.2;
  border-radius: 50%;
  top: -0.65625rem;
  left: -0.88542rem;
  -webkit-animation: ripple3 3s linear 0s infinite normal;
  animation: ripple3 3s linear 0s infinite normal;
}
.dot2:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  background: linear-gradient(90deg, #40fdfb, #27f03c);
  opacity: 0.3;
  border-radius: 50%;
  top: -0.65625rem;
  left: -0.88542rem;
  -webkit-animation: ripple3 3s 1s linear infinite normal;
  animation: ripple3 3s linear 1s infinite normal;
}
.dot3:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  background: linear-gradient(90deg, #40fdfb, #27f03c);
  opacity: 0.2;
  border-radius: 50%;
  top: -0.65625rem;
  left: -0.88542rem;
  animation: ripple3 3s linear 2s infinite normal;
  -webkit-animation: ripple3 3s 2s linear infinite normal;
}
@keyframes ripple3 {
  0% {
    top: 0.78125rem;
    left: 0.41667rem;
    opcity: 75;
    width: 0.3125rem;
    height: 0.3125rem;
  }
  100% {
    top: -0.65625rem;
    left: -0.88542rem;
    opacity: 0;
    width: 3.04688rem;
    height: 3.04688rem;
  }
}

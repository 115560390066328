.join {
  background: #fff;
}
.join .common-title {
  display: block;
  font-size: 0.29167rem;
}
.join .common-title-sub {
  color: #000000;
  font-size: 0.14583rem;
}
.join-banner {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Ffriend-banner1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  align-items: center;
}
.join-banner-logo {
  display: flex;
  align-items: center;
  margin-right: 0.15625rem;
}
.join-banner-logo > img {
  width: 1.43229rem;
  height: 0.26042rem;
  margin-top: 0.05208rem;
  margin-right: 0.02604rem;
}
.join-banner-logo > span {
  color: #FFFFFF;
  font-size: 0.20833rem;
}
.join-banner .common-btn {
  display: flex;
  padding: 0.07813rem 0.26563rem;
  background: linear-gradient(-90deg, #0bfc6e, #63def5);
  border-radius: 0.28125rem;
  font-size: 0.09375rem;
  color: #ffffff;
  align-items: center;
  margin-bottom: 0.16667rem;
}
.join-banner > h1 {
  margin: 0;
  padding-top: 0.28646rem;
  padding-bottom: 0.21875rem;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.41667rem;
}
.join-banner > h1 > span {
  font-size: 0.22917rem;
}
.join-banner > p {
  text-align: center;
  color: #ffffff;
  font-size: 0.08333rem;
  margin-top: 0.07813rem;
  line-height: 0.11458rem;
  letter-spacing: 0.09375rem;
}
.join-banner-more {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 0.25rem;
  line-height: 0.25rem;
  background: linear-gradient(30deg, #FF1C41, #8B2981);
  color: #ffffff;
  font-size: 0.07292rem;
  font-weight: bold;
  text-align: center;
}
.join-banner-more .more-btn {
  margin: 0 0.20833rem 0 0.98958rem;
}
.join-banner-more.disappear {
  animation: disappear 3s;
  -moz-animation: disappear 3s;
  -webkit-animation: disappear 3s;
  -o-animation: disappear 3s;
  opacity: 0;
}
@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#fuwu {
  padding-bottom: 0.17708rem;
}
.advantage-details {
  width: 8.48958rem;
  padding-top: 0.52604rem;
  margin: 0 auto;
  position: relative;
}
.advantage-details-item {
  margin-top: 0.38542rem;
  position: relative;
}
.advantage-details-item .point-list {
  color: #FFFFFF;
  position: relative;
  display: none;
  transition: all 0.5s;
}
.advantage-details-item .point-list.show {
  display: block;
}
.advantage-details-item .point-list-item {
  position: absolute;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
}
.advantage-details-item .point-list-item .hidden-item {
  display: none;
}
.advantage-details-item .point-list-item.show-country {
  animation: fade_In 0.8s forwards;
}
@keyframes fade_In {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.advantage-details-item .point-list-item > .point-icon {
  width: 0.11979rem;
  height: 0.14583rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fpoint2@2x.png");
  background-size: 100% 100%;
  margin-right: 0.02604rem;
  transition: transform 0.3s linear;
}
.advantage-details-item .point-list-item > .place-name {
  font-size: 0.08333rem;
}
.advantage-details-item .point-list-item:nth-child(1) {
  left: 3.88021rem;
  top: -2.1875rem;
}
.advantage-details-item .point-list-item:nth-child(2) {
  left: 4.16667rem;
  top: -2.36979rem;
}
.advantage-details-item .point-list-item:nth-child(3) {
  left: 3.61979rem;
  top: -1.97917rem;
}
.advantage-details-item .point-list-item:nth-child(4) {
  left: 4.0625rem;
  top: -2.55208rem;
}
.advantage-details-item .point-list-item:nth-child(5) {
  left: 4.76563rem;
  top: -2.52604rem;
}
.advantage-details-item .point-list-item:nth-child(6) {
  left: 3.67188rem;
  top: -1.75521rem;
}
.advantage-details-item .point-list-item:nth-child(7) {
  left: 3.89583rem;
  top: -1.625rem;
}
.advantage-details-item .friend-item-dot {
  position: absolute;
  display: none;
  top: 0.96354rem;
  left: 3.33854rem;
  transition: all 0.5s;
}
.advantage-details-item .friend-item-dot.show {
  display: block;
}
.advantage-details-item .friend-dot:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  background: linear-gradient(-90deg, #3296fa, #3350ee);
  opacity: 0.12;
  border-radius: 50%;
  top: -0.76042rem;
  left: -0.88542rem;
  -webkit-animation: ripple3 3s linear 0s infinite normal;
  animation: ripple3 3s linear 0s infinite normal;
}
.advantage-details-item .friend-dot2:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  background: linear-gradient(-90deg, #3296fa, #3350ee);
  opacity: 0.2;
  border-radius: 50%;
  top: -0.76042rem;
  left: -0.88542rem;
  -webkit-animation: ripple3 3s 1s linear infinite normal;
  animation: ripple3 3s linear 1s infinite normal;
}
.advantage-details-item .friend-dot3:after {
  content: ' ';
  position: absolute;
  z-index: 1;
  background: linear-gradient(-90deg, #3296fa, #3350ee);
  opacity: 0.2;
  border-radius: 50%;
  top: -0.76042rem;
  left: -0.88542rem;
  animation: ripple3 3s linear 2s infinite normal;
  -webkit-animation: ripple3 3s 2s linear infinite normal;
}
@keyframes ripple3 {
  0% {
    top: 1.14583rem;
    left: 0.83333rem;
    width: 0.3125rem;
    height: 0.3125rem;
  }
  100% {
    top: -0.76042rem;
    left: -0.88542rem;
    opacity: 0;
    width: 3.90625rem;
    height: 3.90625rem;
  }
}
.advantage-details-item-left {
  box-sizing: border-box;
  width: 10rem;
  background-size: auto 100%;
  background-position: center left;
  background-repeat: no-repeat;
  transition: all 0.2s;
  border-radius: 0.07813rem;
  overflow: hidden;
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fright-market.png);
}
.advantage-details-item-left-list {
  margin: 0;
  position: relative;
}
.advantage-details-item-left-list.list2 .advantage-details-item-left-list-item {
  background: rgba(0, 0, 0, 0.38);
}
.advantage-details-item-left-list .gradient-bg {
  background: linear-gradient(-25deg, #0350fb, #804fda) !important;
  opacity: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.5s;
}
.advantage-details-item-left-list .gradient-bg > .triangle {
  border: 0.09375rem solid;
  border-color: transparent transparent transparent #0350fb;
  transform: translateX(0.17188rem);
}
.advantage-details-item-left-list-item {
  color: #ffffff;
  background: rgba(13, 13, 13, 0.5);
  height: 1.32292rem;
  width: 2.73438rem;
  text-align: center;
  align-items: center;
  box-sizing: border-box;
  transition: transform 0.2s linear;
}
.advantage-details-item-left-list-item.active .icon.market {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fmarketed.png");
}
.advantage-details-item-left-list-item.active .icon.safe {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fsafed.png");
}
.advantage-details-item-left-list-item.active .icon.dingwei {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fdingweied.png");
}
.advantage-details-item-left-list-item .icon {
  width: 0.44792rem;
  height: 0.44792rem;
  border: 0.01042rem solid #FFFFFF;
  border-radius: 50%;
  background-size: 105% 105%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.5s linear;
  opacity: 1;
  z-index: 9;
}
.advantage-details-item-left-list-item .icon.market {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fmarket.png");
}
.advantage-details-item-left-list-item .icon.safe {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fsafe.png");
}
.advantage-details-item-left-list-item .icon.dingwei {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fdingwei.png");
}
.advantage-details-item-left-list-item .text {
  font-size: 0.125rem;
  margin-top: 0.10417rem;
  z-index: 9;
}
.advantage-details-item-left-list .two {
  box-sizing: border-box;
}
.advantage-details-item-right {
  border-radius: 0 0.07813rem 0.07813rem 0;
  overflow: hidden;
  position: relative;
  width: 5.75521rem;
}
.advantage-details-item-right > img {
  width: 5.75521rem;
  height: 3.96875rem;
  position: absolute;
}
.advantage-details-item-right .show {
  animation: activeImg 0.5s linear;
  z-index: 88;
}
@keyframes activeImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.advantage-details-item-right .hide {
  animation: unActiveImg 0.5s linear;
  -moz-animation: unActiveImg 2s;
  -webkit-animation: unActiveImg 2s;
  -o-animation: unActiveImg 2s;
}
@keyframes unActiveImg {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.advantage-details .home-banner-two {
  width: 8.48958rem;
  text-align: center;
  background: #F6F6F6;
  margin: 0 auto;
  box-shadow: 0 0.04167rem 0.10417rem 0 #EBF4F8;
  padding: 0.50521rem 0;
  margin-top: 0.41667rem;
  border-radius: 0.07813rem;
}
.advantage-details .home-banner-two-list {
  display: flex;
  justify-content: center;
  position: relative;
}
.advantage-details .home-banner-two-list-bg {
  width: 1.63542rem;
  height: 100%;
  position: absolute;
  border-radius: 0.08333rem;
  top: 0;
  left: 19.2%;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  background: linear-gradient(0deg, rgba(0, 154, 217, 0.8), rgba(0, 184, 255, 0.8));
  box-shadow: 0 0 0.125rem 0 rgba(8, 1, 3, 0.2);
}
.advantage-details .home-banner-two-list-item {
  width: 1.63542rem;
  padding: 0.22396rem 0 0.23958rem 0;
  margin-right: 0.10417rem;
  border-radius: 0.08333rem;
  z-index: 99;
  transition: all 0.5s;
}
.advantage-details .home-banner-two-list-item > .xuhao {
  width: 0.20833rem;
  height: 0.17188rem;
  margin: 0 auto;
  background-size: 100% 100%;
  transition: all 0.5s;
}
.advantage-details .home-banner-two-list-item > .xuhao.xuhao1 {
  width: 0.15625rem;
  height: 0.17188rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F01-black.png");
}
.advantage-details .home-banner-two-list-item > .xuhao.xuhao2 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F02-black.png");
}
.advantage-details .home-banner-two-list-item > .xuhao.xuhao3 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F03-black.png");
}
.advantage-details .home-banner-two-list-item-text1 {
  font-size: 0.10417rem;
  margin: 0.10417rem 0 0.11458rem 0;
}
.advantage-details .home-banner-two-list-item-text2 {
  font-size: 0.05208rem;
  display: inline-block;
  padding: 0.03646rem 0.14583rem 0.03646rem 0.14063rem;
  margin: 0 auto;
  background: #ffffff;
  border: 0.00521rem solid #cccccc;
  border-radius: 0.03646rem;
  color: #454545;
  transition: all 0.5s;
  transform: s;
}
.advantage-details .home-banner-two-list-item-text3 {
  display: inline-block;
  font-size: 0.0625rem;
  color: #666666;
  padding: 0.19271rem 0.10938rem 0.07813rem 0.10938rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.05208rem;
  border-bottom: 0.00521rem solid #D9D9D9;
  transition: all 0.5s;
}
.advantage-details .home-banner-two-list-item-text4 {
  font-size: 0.0625rem;
  color: #666666;
  line-height: 0.125rem;
  transition: all 0.5s;
}
.advantage-details .home-banner-two-list-item-text4 > .gouxuan-iconbox {
  display: inline-block;
  margin-right: 0.05208rem;
  width: 0.09375rem;
  height: 0.0625rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fgx-green@2x(1).png");
  transition: all 0.5s;
  background-size: 100% 100%;
}
.advantage-details .home-banner-two-list-item-btn {
  padding: 0.05208rem 0.26042rem;
  background: #ffffff;
  box-shadow: 0 0.05208rem 0.07292rem 0 rgba(8, 1, 3, 0.06);
  border-radius: 0.28125rem;
  font-size: 0.0625rem;
  color: #454545;
  margin-top: 0.20833rem;
}
.advantage-details .home-banner-two-list .active {
  color: #FFFFFF;
}
.advantage-details .home-banner-two-list .active .home-banner-two-list-item-text3 {
  border-color: #FFFFFF;
  color: #FFFFFF;
}
.advantage-details .home-banner-two-list .active .home-banner-two-list-item-text2 {
  border: 0.00521rem solid #fff;
}
.advantage-details .home-banner-two-list .active .home-banner-two-list-item-text4 {
  color: #FFFFFF;
}
.advantage-details .home-banner-two-list .active .home-banner-two-list-item-text4 > .gouxuan-iconbox {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fgx-whithe@2x.png");
}
.advantage-details .home-banner-two-list .active > .xuhao1 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F01-white.png");
}
.advantage-details .home-banner-two-list .active > .xuhao2 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F02-white.png");
}
.advantage-details .home-banner-two-list .active > .xuhao3 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F03-white.png");
}
.advantage-details-content {
  width: 8.48958rem;
  height: 3.95833rem;
  border-radius: 0.07813rem;
  background-size: 100% 100%;
  box-shadow: 0 0.04167rem 0.10417rem 0 #EBF4F8;
  box-sizing: border-box;
  padding: 0.58333rem 0 0 1.06771rem;
  margin-top: 0.43229rem;
  color: #ffffff;
  transition: all 0.5s;
}
.advantage-details-content .common-btn-bg {
  font-weight: 600;
  font-size: 0.09375rem;
  color: #333333;
  background: #ffffff;
  box-shadow: 0 0.05208rem 0.05208rem 0 rgba(0, 0, 0, 0.1);
  opacity: 0.7;
}
.advantage-details-content .common-btn-bg:hover {
  opacity: 1;
}
.advantage-details-content .title-small {
  font-size: 0.15625rem;
  display: flex;
  align-items: center;
}
.advantage-details-content .title-small > .logo {
  width: 1.04167rem;
  height: 0.16146rem;
  margin-top: 0.04167rem;
  margin-right: 0.02604rem;
  background-size: 100% 100%;
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fchargespot_logo_white2.png);
}
.advantage-details-content .title-big {
  font-size: 0.23958rem;
  margin: 0.13542rem 0 0.41667rem 0;
  font-weight: bold;
  color: #ffffff;
}
.advantage-details-content .common-btn-bg {
  width: 0.69792rem;
  height: 0.28125rem;
  line-height: 0.28125rem;
  text-align: center;
  border-radius: 0.14063rem;
  margin-top: 0.41146rem;
}
.advantage-details-content .common-btn-bg:hover {
  background: linear-gradient(90deg, #73f3ff, #6032f9, #c317ff);
}
.advantage-details-content-list {
  display: flex;
  align-items: center;
  height: 1.14583rem;
  width: 2.97917rem;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  position: relative;
}
.advantage-details-content-list-bg {
  position: absolute;
  width: 0.94792rem;
  height: 1.04167rem;
  top: 0.05208rem;
  left: 0;
  padding-top: 0.18229rem;
  box-sizing: border-box;
  background: linear-gradient(-25deg, #7331b7, #9b1a95);
  border-radius: 0.07813rem;
  transition: -webkit-transform 0.5s;
  transition: all 0.5s;
  transition: all 0.5s, -webkit-transform 0.5s;
}
.advantage-details-content-list-bg.bg2 {
  left: -0.04167rem;
  top: 0;
  width: 1.04167rem;
  height: 1.14583rem;
}
.advantage-details-content-list-bg.small2 {
  left: 0.08854rem !important;
}
.advantage-details-content-list-bg.small3 {
  left: 0.15625rem !important;
}
.advantage-details-content-list-bg2 {
  position: absolute;
  top: 0;
  left: -0.02604rem;
  width: 1.02083rem;
  height: 1.14583rem;
  padding-top: 0.18229rem;
  box-sizing: border-box;
  background: linear-gradient(-25deg, #7331b7, #9b1a95);
  border-radius: 0.07813rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  opacity: 0;
}
.advantage-details-content-list-item {
  width: 0.94792rem;
  height: 1.04167rem;
  background: #000000;
  opacity: 0.5;
  border-radius: 0.07813rem;
  color: #ffffff;
  padding: 0.13542rem 0 0 0.05208rem;
  box-sizing: border-box;
  transition: all 0.5s;
}
.advantage-details-content-list-item .test1 {
  font-size: 0.16667rem;
  width: 0.23958rem;
  border-bottom: 0.04167rem solid #73F3FF;
  padding-bottom: 0.11458rem;
  transition: all 0.5s;
}
.advantage-details-content-list-item .test1.three {
  border-bottom: 0.04167rem solid #4D53F7;
}
.advantage-details-content-list-item .test2 {
  font-size: 0.14583rem;
  margin: 0.10938rem 0 0.05729rem 0;
}
.advantage-details-content-list-item .test3 {
  font-size: 0.09375rem;
}
.advantage-details-content-list-item.active {
  z-index: 3;
  background: none;
  opacity: 1;
}
.advantage-details-content-list-item.active .test1 {
  border-bottom: 0.04167rem solid #FFFFFF;
}
.advantage-details-content .common-btn-bg {
  background: #ffffff;
  color: #333333;
  opacity: 0.76;
}
.advantage-details-content .common-btn-bg:hover {
  color: #ffffff;
}
.friend-last-banner {
  margin-bottom: 0.17708rem;
}

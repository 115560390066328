.hidden {
  display: none;
}
.home {
  background: #f8f8f8;
}
.home .common-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .common-btn > img {
  width: 0.10938rem;
  height: 0.08333rem;
}
.home .home-modal {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  display: none;
}
.home .home-modal .home-modal-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .home-modal-body {
  background-color: #ffffff;
  width: 2.8125rem;
  height: 2.9375rem;
  border: 0.00521rem solid #e5e5e5;
  border-radius: 0.04167rem;
}
.home .home-modal-body > p {
  font-size: 0.125rem;
  font-family: Microsoft YaHei;
  color: #000000;
  text-align: center;
  margin-top: 0.06771rem;
  margin-bottom: 0.0625rem;
}
.home .home-modal-body > .home-modal-content {
  font-size: 0.08333rem;
  height: 2.26563rem;
  overflow-y: scroll;
  color: #000000;
  line-height: 0.15625rem;
  padding: 0 0.19792rem;
  white-space: pre-wrap;
}
.home .home-modal-body > .home-modal-content::-webkit-scrollbar {
  display: none;
}
.home .home-modal-body > button {
  width: 2.5rem;
  height: 0.23958rem;
  background: linear-gradient(0deg, #1e9df7, #31e9ff);
  border-radius: 0.11979rem;
  font-size: 0.10417rem;
  margin: 0.06771rem 0.15625rem;
  color: #ffffff;
}
.home .home-modal > span {
  display: inline-block;
  color: #999999;
  font-size: 0.11458rem;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 5%;
  user-select: none;
}
.home .home-banner-one {
  position: relative;
  width: 100%;
  height: 100vh;
}
.home .home-banner-one-logo {
  display: flex;
  align-items: center;
  margin-bottom: 0.10417rem;
}
.home .home-banner-one-logo > span {
  font-size: 0.26042rem;
  color: #ffffff;
}
.home .home-banner-one-logo > img {
  width: 1.51042rem;
  height: 0.26042rem;
  margin-top: 0.02604rem;
  margin-right: 0.01042rem;
}
.home .home-banner-one .swiper-button-prev,
.home .home-banner-one .swiper-button-next {
  color: #FFFFFF;
  border: 0.01042rem solid #ffffff;
}
.home .home-banner-one .swiper-button-prev {
  bottom: 0;
  left: auto;
  right: 0.25rem;
}
.home .home-banner-one .swiper-button-next {
  bottom: 0;
  right: 0;
}
.home .home-banner-one .swiper-button-next:after,
.home .home-banner-one .swiper-button-prev:after {
  font-weight: bold;
}
.home .home-banner-one .swiper-button-next:hover,
.home .home-banner-one .swiper-button-prev:hover {
  color: #001214;
  background: #FFFFFF;
}
.home .home-banner-one .swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.home .home-banner-one .swiper-container .swiper-tool-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  right: 0;
  position: absolute;
  bottom: 0.51042rem;
}
.home .home-banner-one .swiper-container .swiper-pagination {
  text-align: center;
}
.home .home-banner-one .swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 0.05208rem;
  height: 0.05208rem;
  border: 0.01042rem solid #FFFFFF !important;
  margin: 0 0.05208rem !important;
}
.home .home-banner-one .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #ffffff;
  box-shadow: 0 0 0.01042rem 0.01042rem rgba(255, 255, 255, 0.6);
}
.home .home-banner-one .swiper-container .swiper-wrapper {
  width: 100%;
  height: 100%;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body {
  position: relative;
  width: 100%;
  height: 100%;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .swiper-slide-banner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .swiper-slide-banner.banner1 {
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fbanner-1.png);
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .swiper-slide-banner.banner2 {
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fbanner-2.png);
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .swiper-slide-banner.banner3 {
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fbanner-3.png);
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .swiper-slide-banner.banner4 {
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fbanner-4.png);
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .swiper-slide-banner.banner5 {
  background-image: url(http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fbanner-5.png);
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body > img {
  width: 100%;
  height: 100%;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .apply-btn {
  font-size: 0.08333rem;
  position: absolute;
  left: 2.57813rem;
  top: 3.51563rem;
  width: 0.57292rem;
  height: 0.25rem;
  background: #ffffff;
  border-radius: 0.125rem;
  cursor: pointer;
  color: #000000;
  font-weight: bold;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .apply-btn:hover,
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body .apply-btn:active {
  color: #fff;
  background: linear-gradient(90deg, #1162f0, #8c14f1);
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .two .apply-btn,
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .four .apply-btn {
  left: auto;
  right: 3.73958rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body-flex {
  display: flex;
  justify-content: space-between;
  width: 6.25rem;
  margin: 0 auto;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body-flex-right {
  text-align: right;
  color: #FFFFFF;
  position: relative;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body-flex-right .padtop {
  font-size: 0.15625rem;
  line-height: 0.20833rem;
  margin: 0.10938rem 0 0.45313rem 0;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body-flex-right .padtop.right-padtop {
  text-align: right;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body-flex-right .pad-white {
  font-size: 0.09375rem;
  line-height: 0.13021rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide .swiper-slide-body-flex-right .charsport {
  font-size: 0.08333rem;
  transform: rotate(90deg);
  position: absolute;
  bottom: 30%;
  right: -46%;
  letter-spacing: 0.02604rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one > h1 {
  padding-top: 1.34896rem;
  font-size: 0.33333rem;
  font-weight: 600;
  letter-spacing: 0.01563rem;
  color: #FFFFFF;
  line-height: 0.46875rem;
  text-align: center;
  margin-bottom: 0;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list {
  width: 4.4375rem;
  height: 1.10938rem;
  margin: 0.13021rem auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.05208rem;
  display: flex;
  flex-wrap: wrap;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item {
  width: 25%;
  display: flex;
  margin-top: 0.15625rem;
  height: 0.3125rem;
  clear: both;
  white-space: nowrap;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon {
  width: 0.23958rem;
  height: 0.15625rem;
  margin-left: 0.26042rem;
  margin-right: 0.10417rem;
  background-size: 100% 100%;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.china {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/china.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.japan {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/japan.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.thailand {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/thailand.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.germany {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/germany.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.malaysia {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/malaysia.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.singapore {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/singapore.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.arab {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/arab.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item .country-icon.america {
  background-image: url('https://chargespot.oss-accelerate.aliyuncs.com/Cnoffical/images/america.png');
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item-content {
  width: 0.46875rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item-content > p {
  color: #FFFFFF;
  font-size: 0.11458rem;
  font-weight: bold;
  line-height: 0.15625rem;
  margin-bottom: 0.02604rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item-content > span {
  font-size: 0.07292rem;
  color: #ffffff;
  line-height: 0.10417rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .coutry-list-item-content > span:not(:last-child) {
  margin-right: 0.02604rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .country-count {
  width: 0.96875rem;
  height: 0.16667rem;
  margin: 0 auto;
  background: #f4f6f6;
  border-radius: 0.02083rem;
  font-weight: 550;
  font-size: 0.08333rem;
  color: #00909D;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.21354rem;
}
@media screen and (max-width: 1280px) {
  .home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .country-count {
    width: 1.04167rem;
  }
}
@media screen and (max-width: 1000px) {
  .home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .country-count {
    width: 1.19792rem;
  }
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.one .country-count > span {
  margin: 0 0.05208rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.two {
  background: #001214;
  padding-top: 1.22917rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.two .common-logo {
  margin-bottom: 0.10417rem;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.two h3 {
  font-size: 0.26042rem;
  color: #00E0FC;
  letter-spacing: 0.01563rem;
  line-height: 0.3125rem;
  margin-bottom: 0.11979rem;
  text-align: left;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.two .text-list {
  font-size: 0.09375rem;
  color: #fff;
  text-align: left;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.two .power {
  width: 0.68229rem;
  height: 0.76042rem;
  border: 0.01563rem solid #ffffff;
  padding: 0.06771rem 0 0 0.06771rem;
  box-sizing: border-box;
  margin: 0.22917rem 0 0.14063rem 0;
}
.home .home-banner-one .swiper-container .swiper-wrapper .swiper-slide.two .power > img {
  width: 0.48438rem;
  height: 0.63021rem;
}
.home .home-banner-two {
  position: relative;
  width: 6.25rem;
  text-align: center;
  background: #F6F6F6;
  margin: 0.07292rem auto;
  box-shadow: 0 0.05208rem 0.09375rem 0 rgba(0, 168, 255, 0.1);
  padding: 0.50521rem 0 0.39583rem 0;
  border-radius: 0.05208rem;
}
.home .home-banner-two .common-logo {
  margin: 0 auto;
}
.home .home-banner-two .common-logo > img {
  width: 1.38542rem;
  height: 0.23438rem;
}
.home .home-banner-two-list {
  display: flex;
  justify-content: center;
  margin-top: 0.26042rem;
  position: relative;
}
.home .home-banner-two-list-bg {
  width: 1.63542rem;
  height: 100%;
  position: absolute;
  border-radius: 0.08333rem;
  top: 0;
  left: 8.2%;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  background: linear-gradient(0deg, rgba(0, 154, 217, 0.8), rgba(0, 184, 255, 0.8));
  box-shadow: 0 0 0.125rem 0 rgba(8, 1, 3, 0.2);
}
.home .home-banner-two-list-item {
  width: 1.63542rem;
  padding: 0.22396rem 0 0.23958rem 0;
  margin-right: 0.10417rem;
  border-radius: 0.08333rem;
  align-items: center;
  z-index: 99;
  transition: all 0.5s;
}
.home .home-banner-two-list-item > .xuhao {
  width: 0.20833rem;
  height: 0.17188rem;
  margin: 0 auto;
  background-size: 100% 100%;
  transition: all 0.5s;
}
.home .home-banner-two-list-item > .xuhao.xuhao1 {
  width: 0.15625rem;
  height: 0.17188rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F01-black.png");
}
.home .home-banner-two-list-item > .xuhao.xuhao2 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F02-black.png");
}
.home .home-banner-two-list-item > .xuhao.xuhao3 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F03-black.png");
}
.home .home-banner-two-list-item-text1 {
  font-size: 0.10417rem;
  margin: 0.10417rem 0 0.11458rem 0;
}
.home .home-banner-two-list-item-text2 {
  font-size: 0.05208rem;
  display: inline-block;
  padding: 0.03646rem 0.14583rem 0.03646rem 0.14063rem;
  margin: 0 auto;
  background: #ffffff;
  border: 0.00521rem solid #cccccc;
  border-radius: 0.03646rem;
  color: #454545;
  transition: all 0.5s;
}
.home .home-banner-two-list-item-text3 {
  font-size: 0.0625rem;
  color: #666666;
  padding: 0.19271rem 0.10938rem 0.07813rem 0.10938rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.05208rem;
  border-bottom: 0.00521rem solid #D9D9D9;
  transition: all 0.5s;
}
.home .home-banner-two-list-item-text4 {
  font-size: 0.0625rem;
  color: #666666;
  line-height: 0.125rem;
  transition: all 0.5s;
}
.home .home-banner-two-list-item-text4 > .gouxuan-iconbox {
  display: inline-block;
  margin-right: 0.05208rem;
  width: 0.09375rem;
  height: 0.0625rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fgx-green@2x(1).png");
  transition: all 0.5s;
  background-size: 100% 100%;
}
.home .home-banner-two-list-item-btn {
  padding: 0.05208rem 0.26042rem;
  background: #ffffff;
  box-shadow: 0 0.05208rem 0.07292rem 0 rgba(8, 1, 3, 0.06);
  border-radius: 0.28125rem;
  font-size: 0.0625rem;
  color: #454545;
  margin-top: 0.20833rem;
}
.home .home-banner-two-list .active {
  color: #FFFFFF;
}
.home .home-banner-two-list .active .home-banner-two-list-item-text2 {
  border: 0.00521rem solid #fff;
}
.home .home-banner-two-list .active .home-banner-two-list-item-text3 {
  border-color: #FFFFFF;
  color: #FFFFFF;
}
.home .home-banner-two-list .active .home-banner-two-list-item-text4 {
  color: #FFFFFF;
}
.home .home-banner-two-list .active .home-banner-two-list-item-text4 > .gouxuan-iconbox {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fgx-whithe@2x.png");
}
.home .home-banner-two-list .active > .xuhao1 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F01-white.png");
}
.home .home-banner-two-list .active > .xuhao2 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F02-white.png");
}
.home .home-banner-two-list .active > .xuhao3 {
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2F03-white.png");
}
.home .home-banner-three {
  position: relative;
  width: 6.25rem;
  margin: 0.15625rem auto;
  box-shadow: 0 0.05208rem 0.07292rem 0 rgba(0, 168, 255, 0.1);
  padding: 0;
  background: #F6F6F6;
}
.home .home-banner-three-content {
  background: #FFFFFF;
  display: flex;
}
.home .home-banner-three-content-left {
  padding: 1.17188rem 0 1.17188rem 0.625rem;
  color: #323237;
}
.home .home-banner-three-content-left-logo {
  display: flex;
  align-items: center;
  margin-bottom: 0.07813rem;
}
.home .home-banner-three-content-left-logo-img {
  height: 0.19792rem;
}
.home .home-banner-three-content-left-logo > span {
  font-size: 0.19792rem;
}
.home .home-banner-three-content-left-text1 {
  font-size: 0.29167rem;
  font-weight: bold;
  margin-bottom: 0.07813rem;
}
.home .home-banner-three-content-left-text2 {
  font-size: 0.125rem;
  font-weight: bold;
  line-height: 0.20833rem;
}
.home .home-banner-three-content-left-text3 {
  font-size: 0.08854rem;
  margin: 0.10938rem 0;
}
.home .home-banner-three-content-left-btn {
  width: 1.11458rem;
  height: 0.25rem;
  background: linear-gradient(-100deg, #00B4FF, #63DEF5);
  border-radius: 0.125rem;
  color: #FFFFFF;
  font-size: 0.09375rem;
}
.home .home-banner-three-content-right {
  transform: translateX(0.18229rem);
  position: relative;
  overflow: auto;
}
.home .home-banner-three-content-right > img {
  width: 3.10417rem;
  height: 2.57813rem;
  z-index: 9;
}
.home .home-banner-three-content-right .product-container {
  position: relative;
  margin-top: 0.1875rem;
  height: 1.01042rem;
}
.home .home-banner-three-content-right .product-let {
  transition: all 0.1s;
  transform: translate3d(0.19792rem, 0.1875rem, 0);
  z-index: -1;
  position: -webkit-sticky;
  position: sticky;
  top: 0.10417rem;
  white-space: nowrap;
}
.home .home-banner-three-content-right .product-let > img {
  width: 0.3125rem;
  height: 1.01042rem;
  transition: all 0.1s;
}
.home .home-banner-three-content-right .product-let > img:nth-child(1) {
  transform: translate3d(25PX, 0, 0);
  z-index: 1;
}
.home .home-banner-three-content-right .product-let > img:nth-child(2) {
  transform: translate3d(10PX, 0, 0);
  z-index: 2;
}
.home .home-banner-three-content-right .product-let > img:nth-child(3) {
  transform: translate3d(-5PX, 0, 0);
  z-index: 3;
}
.home .home-banner-three-content-right .product-let > img:nth-child(4) {
  z-index: 4;
  transform: translate3d(-20PX, 0, 0);
}
.home .home-banner-three-content-right .product-let > img:nth-child(5) {
  z-index: 5;
  transform: translate3d(-35PX, 0, 0);
}
.home .home-banner-three-content-right .product-right {
  transition: all 0.2s;
  transform: translate3d(-0.25rem, 0, 0);
  z-index: -1;
  white-space: nowrap;
}
.home .home-banner-three-content-right .product-right > img {
  width: 0.3125rem;
  height: 1.01042rem;
  transition: all 0.1s;
}
.home .home-banner-three-content-right .product-right > img:nth-child(1) {
  transform: translate3d(25PX, 0, 0);
  z-index: 1;
}
.home .home-banner-three-content-right .product-right > img:nth-child(2) {
  transform: translate3d(10PX, 0, 0);
  z-index: 2;
}
.home .home-banner-three-content-right .product-right > img:nth-child(3) {
  transform: translate3d(-5PX, 0, 0);
  z-index: 3;
}
.home .home-banner-three-content-right .product-right > img:nth-child(4) {
  z-index: 4;
  transform: translate3d(-20PX, 0, 0);
}
.home .home-banner-three-content-right .product-right > img:nth-child(5) {
  z-index: 5;
  transform: translate3d(-35PX, 0, 0);
}
.home .home-banner-three-content-right .img-up {
  transform: translate3d(0, 0.15625rem, 0);
}
.home .home-banner-three-content-right .img-down {
  transform: translate3d(0, -0.15625rem, 0);
}
.home .home-banner-four {
  width: 6.25rem;
  height: 1.97917rem;
  display: flex;
  margin: 0 auto;
  margin-bottom: 0.14583rem;
  font-size: 0.09375rem;
  color: #ffffff;
}
.home .home-banner-four-btn {
  width: 1.64583rem;
  height: 0.25rem;
  background: #FFFFFF;
  border-radius: 0.125rem;
  margin: 0.41667rem 0 0.57292rem 0;
  color: #000000;
  font-size: 0.09375rem;
}
.home .home-banner-four-left {
  height: 100%;
  width: 4.30208rem;
  margin-right: 0.05208rem;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Ffriend@2x.png");
  background-size: 100% 100%;
  position: relative;
  align-items: center;
}
.home .home-banner-four-left > img {
  position: absolute;
  top: 0;
}
.home .home-banner-four-left .home-banner-four-big {
  font-size: 0.25rem;
}
.home .home-banner-four-left .home-banner-four-small {
  position: absolute;
  font-size: 0.10417rem;
  text-shadow: 0 0 0.0625rem rgba(0, 0, 0, 0.3);
  bottom: 0.18229rem;
  text-align: center;
}
.home .home-banner-four-right {
  height: 100%;
  width: 1.89583rem;
  background-size: 100% 100%;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fusing@2x.png");
  position: relative;
  align-items: center;
}
.home .home-banner-four-right-icon {
  width: 0.25521rem;
  height: 0.25521rem;
  background-size: 100% 100%;
  background-image: url("http://chargespot.oss-cn-hongkong.aliyuncs.com/Cnoffical%2FImgs%2Fdongtaiquan@2x.png");
}
.home .home-banner-four-right > img {
  position: absolute;
  top: 0;
}
.home .home-banner-four-right .home-banner-four-small {
  position: absolute;
  font-size: 0.10417rem;
  text-shadow: 0 0 0.0625rem rgba(0, 0, 0, 0.3);
  bottom: 0.18229rem;
  text-align: center;
}
.home .home-banner-five {
  position: relative;
}
.home .home-banner-five-body {
  width: 6.25rem;
  height: 3.38542rem;
  background: linear-gradient(225deg, #00ff69, #63def5);
  box-shadow: 0 0.02604rem 0.15104rem 0.00521rem rgba(0, 168, 255, 0.25);
  margin: 0 auto;
  color: #323237;
}
.home .home-banner-five-body-left {
  position: relative;
  width: 3.39063rem;
}
.home .home-banner-five-body-left .logo {
  margin: 12% 0 6% 0;
  width: 0.70313rem;
  height: 0.35417rem;
}
.home .home-banner-five-body-left .product-img {
  position: absolute;
  bottom: 0;
  left: 0.3125rem;
  width: 2.69792rem;
  height: 2.50521rem;
}
.home .home-banner-five-body-right {
  padding-top: 0.71875rem;
}
.home .home-banner-five-body-right-text1 {
  font-size: 0.13542rem;
  font-weight: bold;
}
.home .home-banner-five-body-right-text2 {
  width: 2.18229rem;
  font-size: 0.35417rem;
  margin: 0.11458rem 0 0.32813rem 0;
  font-weight: 800;
}
.home .home-banner-five-body-right-text3 {
  font-size: 0.23958rem;
  color: #0F0F0F;
}
.home .home-banner-five-body-right-text4 {
  color: #0F0F0F;
  font-size: 0.08333rem;
  width: 1.19271rem;
  margin-bottom: 0.10417rem;
  margin-top: 0.03646rem;
  line-height: 0.10417rem;
}
.home .home-banner-five-body-btn {
  padding: 0.07292rem 0.25rem;
  display: inline-block;
  background: #ffffff;
  border-radius: 0.125rem;
  font-size: 0.09375rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0.01042rem 0.05208rem 0 rgba(0, 0, 0, 0.08);
  transition: transform 0.2s linear;
  -webkit-transition: transform 0.2s linear;
}
.home .home-banner-five-body-btn > img {
  margin-left: 0.08854rem;
  width: 0.10938rem;
  height: 0.08333rem;
}
.home .home-banner-five-body-btn:hover {
  color: #FFFFFF;
  transform: translate3d(0, -0.00521rem, 0);
  background: linear-gradient(90deg, #0bfc6e 0%, #63def5 100%);
}
.home .home-banner6 {
  position: relative;
}
.home .home-banner6-maodianTop {
  top: -0.51042rem;
}
.home .home-banner-seven {
  position: relative;
  padding-top: 0.46875rem;
  padding-bottom: 0.53646rem;
  background-color: #FFFFFF;
}
.home .home-banner-seven-logo {
  width: 1.40625rem;
  margin: 0 auto;
}
.home .home-banner-seven-logo > img {
  width: 1.40625rem;
  height: 0.23438rem;
}
.home .home-banner-seven-title {
  color: #000000;
  font-size: 0.16667rem;
  text-align: center;
  font-weight: bold;
  margin-top: 0.05208rem;
}
.home .home-banner-seven-swiper .swiper-info {
  display: none;
}
.home .home-banner-seven-swiper .poster-item-active > img {
  height: 100%;
}
.home .home-banner-seven-swiper .poster-item > img {
  border-radius: 0.07813rem;
}
.home .home-banner-seven-body {
  width: 5.52083rem;
  height: auto;
  margin: 0 auto;
}
.home .home-banner-seven-footer {
  font-size: 0.10417rem;
  text-align: center;
  margin-top: 0.46875rem;
  color: #000000;
  line-height: 0.125rem;
}
.home .home-banner-seven-footer .loading {
  text-align: center;
  margin-left: 0.36458rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .home-banner-seven-footer .loading > span {
  display: inline-block;
  width: 0.04167rem;
  height: 0.04167rem;
  margin-left: 0.02604rem;
  border-radius: 50%;
  background: #000000;
  animation: load 1.04s ease infinite;
  -webkit-animation: load 1.04s ease infinite;
}
.home .home-banner-seven-footer .loading > span:last-child {
  margin-right: 0;
}
.home .home-banner-seven-footer .loading > span:nth-child(1) {
  animation-delay: 0.13s;
  -webkit-animation-delay: 0.13s;
}
.home .home-banner-seven-footer .loading > span:nth-child(2) {
  animation-delay: 0.26s;
  -webkit-animation-delay: 0.26s;
}
.home .home-banner-seven-footer .loading > span:nth-child(3) {
  animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
}
.home .home-banner-seven-footer .loading > span:nth-child(4) {
  animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
}
.home .home-banner-seven-footer .loading > span:nth-child(5) {
  animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
}
@keyframes load {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.4);
  }
}
.home .home-banner-seven-card {
  display: flex;
  margin-bottom: 0.10417rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.home .home-banner-seven-card-item {
  transition: all 2s linear;
  -webkit-transition: all 2s linear;
  position: relative;
  transition: all 0.5s;
  width: 1.29167rem;
  height: 0.66667rem;
  box-shadow: 0 0.05208rem 0.07292rem 0 rgba(0, 112, 192, 0.06);
  border-radius: 0.05208rem;
  margin-bottom: 0.13021rem;
  background: #FFFFFF;
}
.home .home-banner-seven-card-item:hover {
  box-shadow: 0 0.06771rem 0.10417rem 0 rgba(0, 112, 192, 0.14);
  transform: translate3d(0, -0.07813rem, 0);
}
.home .home-banner-seven-card-item-tip {
  position: absolute;
  bottom: 0;
  left: 0;
}
.home .home-banner-seven-card-item > img {
  width: 100%;
  height: 100%;
  border-radius: 0.05208rem;
  box-shadow: 0 0.05208rem 0.07292rem 0 rgba(0, 112, 192, 0.06);
}
.swiper-button-prev,
.swiper-button-next {
  border-radius: 50%;
  width: 0.20833rem;
  height: 0.20833rem;
  line-height: 0.26042rem;
  color: #00a2ff;
  outline: none;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 0.13542rem;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #fff;
  background: #00a2ff;
}

.about {
  background-color: #f8f8f8;
}
.about-banner {
  position: relative;
  width: 100%;
  height: 5.10417rem;
  background-image: url("https://chargespot.oss-accelerate.aliyuncs.com/chargespot/img_about_us.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.about-banner > h1 {
  margin: 0;
  padding-top: 1.45833rem;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.35417rem;
  line-height: 0.46875rem;
  margin-right: 0.15625rem;
}
.about-banner > p {
  text-align: center;
  color: #ffffff;
  font-size: 0.08333rem;
  margin-top: 0.07813rem;
  line-height: 0.11458rem;
  letter-spacing: 0.09375rem;
}
.about-company {
  position: relative;
  width: 100%;
  height: 3.69792rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.41146rem;
  box-sizing: border-box;
}
.about-company-img {
  width: 3.16667rem;
  height: 2.25521rem;
  background-image: url("https://chargespot.oss-accelerate.aliyuncs.com/chargespot/img_us.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-company-body {
  margin-right: 0.35417rem;
  color: #333333;
}
.about-company-body-title {
  font-size: 0.25rem;
  font-weight: bold;
  line-height: 0.34896rem;
  margin-bottom: 0.15625rem;
}
.about-company-body-content {
  width: 2.85417rem;
  margin-bottom: 0.13021rem;
  font-size: 0.08333rem;
  line-height: 0.14583rem;
  color: #323237;
}
.about-company-body-content.last {
  margin-bottom: 0;
}
.about-company-body-split {
  height: 0.02083rem;
  width: 0.15625rem;
  background: #0591b4;
  margin-top: 0.07813rem;
}
.development {
  position: relative;
  width: 100%;
  height: 4.58333rem;
  background-image: url("/static/img/develop-history2.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.development-body {
  color: #FFFFFF;
  text-align: center;
  padding-top: 0.36458rem;
}
.development-body > img {
  width: 1.81771rem;
  height: 0.25521rem;
}
.development-body-title {
  font-size: 0.1875rem;
  margin-top: 0.04688rem;
}
.contact-us {
  padding-top: 0.52083rem;
  padding-bottom: 0.57292rem;
}
.contact-us-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-us-logo > img {
  width: 1.35417rem;
  height: 0.25521rem;
  margin-right: 0.0625rem;
  margin-top: 0.07813rem;
}
.contact-us-logo > span {
  font-size: 0.23958rem;
  color: #404040;
  letter-spacing: 0.01563rem;
}
.contact-us .common-title-sub {
  font-size: 0.1875rem;
  margin-top: 0.04688rem;
  letter-spacing: 0.01042rem;
}
.contact-us-line {
  margin-top: 0.15625rem;
}
.contact-us-line.max {
  margin-top: 0.3125rem;
}
.contact-us-line.max > .contact-us-line-body {
  padding: 0.20833rem;
  background: #FFFFFF;
  box-shadow: 0 0.01042rem 0.10417rem 0 rgba(0, 0, 0, 0.14);
}
.contact-us-line.max .contact-us-line-right {
  margin-left: 0.27604rem;
}
.contact-us-line-body {
  width: 6.25rem;
  padding: 0.20833rem 0 0.20833rem 0.48438rem;
  box-sizing: border-box;
  margin: auto;
}
.contact-us-line-body.border {
  border-bottom: 0.00521rem solid #DDDDDD;
}
.contact-us-line-left {
  width: 1.07292rem;
  font-size: 0.11458rem;
  font-weight: bold;
  color: #333333;
  display: flex;
  align-items: center;
}
.contact-us-line-left-logo {
  width: 0.17708rem;
  height: 0.18229rem;
  display: inline-block;
  background-image: url("/static/img/about-logo.png");
  background-size: 100% 100%;
  margin-right: 0.08333rem;
}
.contact-us-line-left.big {
  font-size: 0.15625rem;
  line-height: 0.20833rem;
}
.contact-us-line-right {
  flex: 1;
  margin-top: 0.25521rem;
}
.contact-us-line-right-line {
  display: flex;
  min-height: 0.15625rem;
  align-items: center;
}
.contact-us-line-right-line.big {
  position: relative;
  margin-bottom: 0.15625rem;
  min-height: 0.20833rem;
}
.contact-us-line-right-line.big.tel-line {
  margin-bottom: 0;
}
.contact-us-line-right-line.big.address {
  height: 0.39063rem;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contact-us-line-right-line.big.address :first-child {
  margin-bottom: 0.07813rem;
}
.contact-us-line-right-line.big > .tel-icon {
  width: 0.08333rem;
}
.contact-us-line-right-line.big > img {
  width: 0.07292rem;
  height: 0.09375rem;
}
.contact-us-line-right-line.big > span {
  font-size: 0.10417rem;
  color: #666666;
  line-height: 0.15104rem;
  margin-left: 0.15104rem;
}
.contact-us-line-right-line.big > span.tel {
  font-size: 0.10417rem;
  background: linear-gradient(-90deg, #13d1a8 0%, #23b7e4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0.01563rem;
  margin-left: 0.14583rem;
}
.contact-us-line-right-line.big .address_pazhou {
  margin-left: 0.22396rem;
}
.contact-us-line-right-line > img {
  width: 0.07292rem;
  height: 0.09375rem;
}
.contact-us-line-right-line > span {
  font-size: 0.10417rem;
  color: #666666;
  line-height: 0.15104rem;
  margin-left: 0.15104rem;
}

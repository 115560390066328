.detail {
  padding: 0.625rem 0 0.20833rem;
  background: #F9F9F9;
}
.detail .detail-content {
  width: 6.25rem;
  margin: 0 auto;
}
.detail .detail-content-tab {
  font-size: 0.09896rem;
  color: #333333;
  padding: 0.15625rem 0;
}
.detail .detail-content-tab > a {
  color: #999999;
}
.detail .detail-content-body {
  background: #ffffff;
  box-shadow: 0 0.03125rem 0.05208rem 0 rgba(0, 168, 255, 0.06);
}
.detail .detail-content-body-head {
  border-bottom: 0.00521rem solid #DDDDDD;
  padding-left: 0.56771rem;
  padding-top: 0.20833rem;
}
.detail .detail-content-body-head-title {
  font-size: 0.16667rem;
  color: #323237;
}
.detail .detail-content-body-head-type {
  display: flex;
  align-items: center;
  color: #999999;
  font-size: 0.08333rem;
  margin: 0.20833rem 0 0.13021rem 0;
}
.detail .detail-content-body-head-type .using-type {
  padding: 0.02604rem 0.05208rem 0.02083rem 0.05208rem;
  text-align: center;
  border: 0.00521rem solid #2C84FA;
  border-radius: 0.02083rem;
  color: #2C84FA;
}
.detail .detail-content-body-head-type .using-send {
  margin: 0 0.26042rem;
  display: flex;
  align-items: center;
}
.detail .detail-content-body-head-type .using-send > img {
  width: 0.06771rem;
  height: 0.07292rem;
  margin-right: 0.05208rem;
}
.detail .detail-content-body-head-type .using-time {
  display: flex;
  align-items: center;
}
.detail .detail-content-body-head-type .using-time > img {
  width: 0.06771rem;
  height: 0.07292rem;
  margin-right: 0.04167rem;
}
.detail .detail-content-body-main {
  padding: 0.26042rem 0.56771rem;
  font-size: 0.09375rem;
}
.detail .detail-content-body-main > img {
  width: 100%;
  margin: 0.10417rem 0;
}

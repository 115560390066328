.new-video {
  position: fixed;
  display: none;
  width: 6.25rem;
  height: 3.125rem;
  z-index: 99;
  top: 17%;
  left: 22.8%;
}
.new-video .new-video-box {
  background: #000000;
  position: relative;
}
.new-video .new-video-box > .video-react-controls-enabled {
  padding-top: 50% !important;
}
.new-video .new-video-box > .video-react-controls-enabled .video-react-big-play-button {
  display: none;
}
.new-video .new-video-box > .react-video {
  width: 100%;
  height: 100%;
}
.new-video .new-video-box > .video_play {
  width: 0.3125rem;
  height: 0.3125rem;
  background-image: url("/static/img/video_close.png");
  background-size: cover;
  position: absolute;
  top: -0.14583rem;
  right: -0.35417rem;
}
#detail-swiper {
  position: relative;
  width: 6.25rem;
  height: 1.10417rem;
  margin: 0 auto;
  padding: 0.20833rem 0.46875rem;
}
.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-container .swiper-slide {
  text-align: center;
  font-size: 0.09375rem;
  background: #fff;
}
.swiper-container .swiper-slide-card {
  width: 100%;
}
.swiper-container .swiper-slide-card .card-swiper-img {
  width: 100%;
  height: 0.78125rem;
  margin-bottom: 0.08333rem;
}
.swiper-container .swiper-slide-card .new-box {
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-container .swiper-slide-card .new-box .video-play {
  width: 0.15625rem;
  height: 0.15625rem;
  position: absolute;
  top: 35%;
  left: 44.5%;
}
.swiper-container .swiper-slide-card > p {
  padding: 0 0.25521rem 0 0.09896rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.swiper-button-prev,
.swiper-button-next {
  width: 0.27083rem;
  height: 0.27083rem;
  border: 0.01042rem solid #00a2ff;
  border-radius: 50%;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 0.13542rem;
  font-weight: bold;
}
